import {LmsProgressStatus} from '@shared/models/familyInfo';

type Child = {
  id: number,
  name: string,
  birthDate: string,
  gender: string,
};

type AssignedClinician = {
  firstName:string,
  lastName:string,
  ssoId: string,
  id: 0
};

type Country = {
  id: number,
  countryName:string,
};

type Plan = {
  id: number,
  name: string,
};

export type Subscription = {
  plan: Plan,
  trial: true,
  expirationDate: string,
  status: string,
};

type TeamCareMembers =  {
  id: number,
  ssoId: string
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  country: Country,
  familyRole: string,
  status: string,
  profileImageUrl: string
};

export interface ClientPrimaryDTO {
    id: number;
    ssoId: string;
    firstName:string;
    lastName: string;
    email: string;
    phoneNumber:string;
    country: Country;
    familyRole :string;
    subscription: Subscription;
    profileImageUrl: string;
    timeZone: string;
    child: Child;
    teamCareMembers: Array<TeamCareMembers>;
    assignedClinician: AssignedClinician;
    lmsProgressStatus: LmsProgressStatus;
}

export class ClientPrimary implements ClientPrimaryDTO{
    id: number;
    ssoId: string;
    firstName:string;
    lastName: string;
    email: string;
    phoneNumber:string;
    country: Country;
    familyRole :string;
    subscription: Subscription;
    profileImageUrl: string;
    timeZone: string;
    child: Child;
    teamCareMembers: Array<TeamCareMembers>;
    assignedClinician: AssignedClinician;
    lmsProgressStatus: LmsProgressStatus;

  constructor(dto: ClientPrimaryDTO) {
    this.update(dto);
  }

  get asJson(): ClientPrimaryDTO {
    return {
      id: this.id,
      ssoId: this.ssoId,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: this.phoneNumber,
      country: this.country,
      familyRole: this.familyRole ,
      subscription: this.subscription,
      profileImageUrl: this.profileImageUrl,
      timeZone: this.timeZone,
      child: this.child,
      teamCareMembers: this.teamCareMembers,
      assignedClinician: this.assignedClinician,
      lmsProgressStatus: this.lmsProgressStatus,
    };
  }

  update(newData: Partial<ClientPrimaryDTO>): ClientPrimaryDTO {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
