enum ASSESSMENT_DOMAINS {
    SEEING_READING = 'SeeingReading',
    HEARING_UNDERSTANDING = 'HearingUnderstanding',
    SENSATION_TACTILITY = 'SensationTactility',
    LOCOMOTION_MOBILITY = 'LocomotionSpeech',
    COMMUNICATION_SPEECH = 'CommunicationSpeech',
    MANUAL_WRITING = 'ManualWriting',
    EMOTIONAL_SOCIAL = 'EmotionalSocial'
}

enum DOMAIN_NAMES {
    SENSORY = 'Sensory',
    MOTOR = 'Motor',
    SOCIAL = 'Social'
}

const DOMAINS_LIST = [
    { id: 1, name: ASSESSMENT_DOMAINS.SEEING_READING, title: 'Seeing and Reading', category: DOMAIN_NAMES.SENSORY },
    { id: 2, name: ASSESSMENT_DOMAINS.HEARING_UNDERSTANDING, title: 'Hearing and Understanding', category: DOMAIN_NAMES.SENSORY },
    { id: 3, name: ASSESSMENT_DOMAINS.SENSATION_TACTILITY, title: 'Sensation and Tactility', category: DOMAIN_NAMES.SENSORY },
    { id: 4, name: ASSESSMENT_DOMAINS.LOCOMOTION_MOBILITY, title: 'Locomotion and Mobility', category: DOMAIN_NAMES.MOTOR },
    { id: 5, name: ASSESSMENT_DOMAINS.COMMUNICATION_SPEECH, title: 'Communication and Speech', category: DOMAIN_NAMES.MOTOR },
    { id: 6, name: ASSESSMENT_DOMAINS.MANUAL_WRITING, title: 'Manual and Writing', category: DOMAIN_NAMES.MOTOR },
    { id: 7, name: ASSESSMENT_DOMAINS.EMOTIONAL_SOCIAL, title: 'Emotional and Social/ Limbic Brain', category: DOMAIN_NAMES.SOCIAL },
];

export {
    DOMAINS_LIST,
    DOMAIN_NAMES,
    ASSESSMENT_DOMAINS
};