import { injectable } from 'inversify';
import container from '@core/di';
import BaseStore from '@core/stores/base';
import {TreatmentPlans, TreatmentPlansDTO} from '@shared/models/treatmentplans';
import TreatmentPlansService from '@shared/services/treatmentplans';

@injectable()
export default class TreatmentPlanStore extends BaseStore<
  TreatmentPlansDTO,
  TreatmentPlans,
  TreatmentPlansService
  > {
  static diToken = Symbol('treatment-plans');

  constructor() {
    super({
      service: container.get<TreatmentPlansService>(TreatmentPlansService.diToken),
    });

    this.loading = {
      list: true,
      item: false,
    };
  }

  getNotApprovedTreatmentPlans = async (page, pageSize) => {
    return await this.service.getNotApprovedTreatmentPlans(page, pageSize);
  }

  getApprovedTreatmentPlans = async (page, pageSize, childId: string) => {
    return await this.service.GetApprovedTreatmentPlans(page, pageSize, childId);
  }

  approveTreatmentPlan = async (childId: string) => {
    return await this.service.ApproveTreatmentPlan(childId);
  }

  rejectTreatmentPlan = async (childId: string, rejectionNote: string) => {
    return await this.service.RejectTreatmentPlan(childId, rejectionNote);
  }

  regenerateTreatmentPlan = async (childId: string) => {
    return await this.service.RegenerateTreatmentPlan(childId);
  }

  getTreatmentOptions = async (childId: string) => {
    return await this.service.GetTreatmentOptions(childId);
  }

  getRejectedTreatmentPlans = async (assessmentId: string) => {
    return await this.service.GetRejectedTreatmentPlans(assessmentId);
  }

  getTreatmentPlanOptions = async (treatmentPlanId: string) => {
    return await this.service.GetTreatmentPlanOptions(treatmentPlanId);
  }

  exportAssesment = async (assessmentId: string) => {
    return await this.service.ExportAssesment(assessmentId);
  }
}