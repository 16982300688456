export interface DraftAssessmentDTO {
  created: string;
  lastModified: string;
  startedByFullName: string;
  ownerFullName: string;
}

export class DraftAssessment implements DraftAssessmentDTO{
  created: string;
  lastModified: string;
  startedByFullName: string;
  ownerFullName: string;

  constructor(dto: DraftAssessmentDTO) {
    this.update(dto);
  }

  get asJson(): DraftAssessmentDTO {
    return {
      created: this.created,
      lastModified: this.lastModified,
      startedByFullName: this.startedByFullName,
      ownerFullName: this.ownerFullName,
    };
  }

  update(newData: Partial<DraftAssessmentDTO>): DraftAssessmentDTO {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
