import SHARED_ROUTES from '@shared/constants/routes';

const ROUTES = {
    public: {
        ...SHARED_ROUTES.public,
        notFound: '/404',
        signoutOidc:'/signout-oidc',

    },
    private: {
        families: '/families',
        familyTreatmentPlanReview: '/treatment-assessment-plan-review/family-treatment-plan-review/:clientPrimaryAccountId/:treatmentPlanId/:childName',
        signoutOidc:'/signout-oidc',
        employee: '/employee',
        materials: '/materials',
        content: '/contentmanagement',
        subscription: '/subscription',
        faq: '/faq',
        client: '/client-assignments',
        assessments: '/assessments',
        profile: '/profile',
        assessmentTreatment: '/treatment-assessment-plan-review',
        assessmentTreatmentTabs: '/treatment-assessment-plan-review/tabs/:tabIndex',
        assessmentSummary: '/treatment-assessment-plan-review/summary/:clientPrimaryAccountId/:childId/:assessmentId',
        startAssessment: '/treatment-assessment-plan-review/start-assessment/:clientPrimaryAccountId/:childId/:assessmentId',
        assessmentTreatmentPlanReview: '/treatment-assessment-plan-review/plan/:childId/:childName',
        assessmentTreatmentReview: '/treatment-assessment-plan-review/:childName/:clientPrimaryAccountId/:childId/:assessmentId/:isSubmitted/:tabIndex',
        assessmentTreatmentRegeneration: '/treatment-assessment-regenerate-plan',
        assessmentTreatmentShowDetails: '/treatment-assessment-show-details',
    },
    initial: '/'
};

export default ROUTES;