import { injectable } from 'inversify';

import container from '@core/di';
import BaseStore from '@core/stores/base';
import { action } from 'mobx';
import AssessmentsHistoryService from 'src/shared/services/assessmentshistory';
import { AssessmentListItem, AssessmentListItemDTO } from '@shared/models/assesmentlistitem';

@injectable()
export default class AssessmentsHistoryStore extends BaseStore<AssessmentListItemDTO, AssessmentListItem, AssessmentsHistoryService> {
    static diToken = Symbol('assessmentshistory-store');

    constructor() {
        super({ service: container.get<AssessmentsHistoryService>(AssessmentsHistoryService.diToken) });

        this.loading = {
            list: false,
            item: false,
        };
    }

    @action getAssessmentsHistoryList = async (clientPrimaryAccountId: number, childId: number) => {
        return await this.service.getAssessmentsHistoryList(clientPrimaryAccountId, childId);
    }

}
