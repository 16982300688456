import { injectable } from 'inversify';

import { CategoriesEvaluations } from '@shared/models/assessmentdetails';
import {  AdditionalCriteriaType, ClinicalCriteriaDTO } from '@shared/models/clinicalcriteria';
import {  EvaluationsDTO } from '@shared/models/evaluations';
import { AbilitiesNote, AdditionalCriteriaNote } from '@shared/models/assessmentdetails';
import { AssessmentsMatrixDTO } from '@shared/models/matrix';
import { Domain } from '@shared/models/assessmentdetails';
import { BrainFunctionEvaluation } from '@shared/models/assessmentdetails';

export interface IAssessmentModelFactory {
  createAssessmentEvaluationModel: (
    matrix: AssessmentsMatrixDTO,
    evaluations: EvaluationsDTO[],
    notes: AbilitiesNote[]
  ) => CategoriesEvaluations[];
  createAdditionalCriteriasEvaluationModel: (
    clinicalCriteria: ClinicalCriteriaDTO[],
    additionalCriteria: AdditionalCriteriaType[],
    notes: AdditionalCriteriaNote[]
  ) => AdditionalCriteriaType[];
}

@injectable()
export class AssessmentModelFactory implements IAssessmentModelFactory {
  static diToken = Symbol('assessments-model-factory');

  getEvaluationRating = (assessments, brainDomain, levelId, brainFunctionId) => {
    const domain = assessments.find((item) => item.brainDomain === brainDomain);
    if(!domain) {
      return null;
    }

    const levelEvaluation = domain.levelEvaluations.find((item) => item.levelId === levelId);
    if(!levelEvaluation) {
      return null;
    }

    const functionsEvaluation = levelEvaluation.functionsEvaluations.find((item) => item.brainFunctionId === brainFunctionId);
    const functionsEvaluationHistory = levelEvaluation.functionsEvaluationsHistory.find((item) => {
      return item.brainFunctionId === brainFunctionId && item.evaluationRating !== functionsEvaluation.evaluationRating;
    });

    if(!functionsEvaluation) {
      return null;
    }

    return {
      functionsEvaluation,
      functionsEvaluationHistory
    };
  };

  createAssessmentEvaluationModel = (matrix, evaluations, notes) => {
    const tabs:CategoriesEvaluations[] = Array(1,2,3,4,5,6,7,8).map((index) => {

      const categoryNamesDuplicate: string[] = [];
      const domainNamesDuplicate: string[] = [];

      const brainFunctionsByLevel = matrix.brainFunctions.filter((item) => {
        categoryNamesDuplicate.push(item.category);
        domainNamesDuplicate.push(item.domain);

        return item.level.brainLevelId === index;
      });

      const categoryNames = Array.from(new Set(categoryNamesDuplicate));
      const domainNames = Array.from(new Set(domainNamesDuplicate));

      const categoriesItem = {};

      categoryNames.forEach((category) => {
        const domains = {};

        const section = brainFunctionsByLevel.filter((item) => item.category === category).map((item) => {

          const rating = this.getEvaluationRating(evaluations, item.domain, item.level.brainLevelId, item.id);
          const currentNote = notes.find((note) => note.brainFunctionId === item.id);

          return {
            ...item,
            evaluation: rating?.functionsEvaluation,
            evaluationHistory: rating?.functionsEvaluationHistory,
            note: currentNote?.text,
            noteAuthor: currentNote?.author?.role
          };
        });

        domainNames.forEach((domain) => {
          const subSection = section.filter((item) => item.domain === domain);
          const levelId = subSection[0]?.level?.brainLevelId;

          Object.assign(
            domains,
            {
              [domain]: new Domain(
                subSection,
                this.getMarkedAsNotYetAchieved(evaluations, domain, levelId),
                this.getDomainTitle(subSection))
            });

        Object.assign(categoriesItem, {[category]: domains});
      });

    });

      return categoriesItem;
    });

    return tabs;
  }

  createAdditionalCriteriasEvaluationModel = (clinicalCriteria, additionalCriteria, notes) => {
    const result = clinicalCriteria.map((item) => {
      const currentNote = notes.find((note) => note.clinicalCriterionId === item.clinicalCriterionId);
      const additionalCriteriaItem = additionalCriteria.find((ac) => ac.clinicalCriterionId === item.clinicalCriterionId);

      const criteria = {
        title: item.title,
        clinicalCriterionId: item.clinicalCriterionId,
        options: item.options,
        option: {},
        note: currentNote?.text,
        noteAuthor: currentNote?.author?.role,
        reviewStatus: additionalCriteriaItem?.reviewStatus,
        historicalClinicalCriterionOptionId: additionalCriteriaItem?.historicalClinicalCriterionOptionId,
        author: additionalCriteriaItem?.author,
        lastModified: additionalCriteriaItem?.lastModified,
      };

      item.options.forEach((option) => {
        if(additionalCriteria.some((i: any) => i.clinicalCriterionOptionId === option.clinicalCriterionOptionID)) {
          Object.assign(criteria, {option});
        }
      });

      return criteria;
    });

    return result;
  }

  getMarkedAsNotYetAchieved = (evaluations: EvaluationsDTO[], brainDomain: string, levelId: number) => {
    const domain = evaluations.find((item) => item.brainDomain === brainDomain);
    if(!domain) {
      return false;
    }

    const evaluation = domain.levelEvaluations.find((item) => item.levelId === levelId);
    if(!evaluation) {
      return false;
    }

    return evaluation.markedAsNotYetAchievedBulk;
  }

  getDomainTitle = (subSection: BrainFunctionEvaluation[]) => {
    if(subSection.length) {
      return subSection[0].domainDescription;
    }

    return '';
  }
}
