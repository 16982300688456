import { observable } from 'mobx';

export enum STATUSES {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  DEACTIVATED = 'Deactivated',
}

export interface UserAccountDTO {
  id: string;
  ssoId: string;
  email: string;
  created: string;
  firstName: string;
  lastName: string;
  role: string;
  status: STATUSES;
  profileImageUrl? :string;
  phoneNumber? :string;
  country?: string;
}
export class UserAccount {
  id: string;
  ssoId: string;
  email: string;
  created: string;
  firstName: string;
  lastName: string;
  @observable role: string;
  status: STATUSES;
  profileImageUrl? :string;
  phoneNumber?: string;
  country?: string;

  constructor(dto: UserAccountDTO) {
    this.update(dto);
  }

  get asJson(): UserAccountDTO {
    return {
      id: this.id,
      ssoId: this.ssoId,
      email: this.email,
      created: this.created,
      firstName: this.firstName,
      lastName: this.lastName,
      role: this.role,
      status: this.status,
    };
  }

  update(newData: Partial<UserAccountDTO>): UserAccountDTO {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
