import { createStyles, Theme } from '@material-ui/core';

import { NotificationType } from './Notification';

export default ({ spacing, palette: { colors, primary } }: Theme) =>
  createStyles({
    root: {},
    [NotificationType.error]: {
      '& $contentWrapper': {
        color: colors.error
      },

      '& $closeBtn': {
        color: colors.error
      }
    },
    [NotificationType.info]: {},
    icon: {
      fontSize: 16,
      marginRight: spacing(2),
    },
    closeButton: {
      padding: 0,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    closeBtn: {
      fontSize: 16,
      fontWeight: 700,
      color: primary.main,
      cursor: 'pointer',

      '&:hover': {
        opacity: .8
      }
    },
    contentWrapper: {
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        marginRight: spacing(3)
      }
    },
    snackbarContentWrapper: {},
    dialogContentWrapper: {
      fontSize: 24,
      marginBottom: spacing(6),
      fontWeight: 700,
    },
    dialog: {
      maxWidth: 400,
      display: 'block',
    },
  });
