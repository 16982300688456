export interface TreatmentPlansDTO {
  treatmentPlanId: string;
  created: string;
  childId: string;
  clientPrimaryAccountId: string;
  clientPrimaryAccountFirstName: string;
  clientPrimaryAccountLastName: string;
  subscriptionPlan: string;
  subscriptionPlanId: number;
  childName: string;
  country: string;
  status: string;
  rejectedNote: string;
  assessmentId: string;
}

export class TreatmentPlans implements TreatmentPlansDTO{
  treatmentPlanId: string;
  created: string;
  childId: string;
  clientPrimaryAccountId: string;
  clientPrimaryAccountFirstName: string;
  clientPrimaryAccountLastName: string;
  subscriptionPlan: string;
  subscriptionPlanId: number;
  childName: string;
  country: string;
  status: string;
  rejectedNote: string;
  assessmentId: string;

  constructor(dto: TreatmentPlansDTO) {
    this.update(dto);
  }

  get asJson(): TreatmentPlansDTO {
    return {
      treatmentPlanId: this.treatmentPlanId,
      created: this.created,
      childId: this.childId,
      clientPrimaryAccountId: this.clientPrimaryAccountId,
      clientPrimaryAccountFirstName: this.clientPrimaryAccountFirstName,
      clientPrimaryAccountLastName: this.clientPrimaryAccountLastName,
      subscriptionPlan: this.subscriptionPlan,
      subscriptionPlanId: this.subscriptionPlanId,
      childName: this.childName,
      country: this.country,
      status: this.status,
      rejectedNote: this.rejectedNote,
      assessmentId: this.assessmentId
    };
  }

  update(newData: Partial<TreatmentPlansDTO>): TreatmentPlansDTO {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
