export const REQUEST_PARAMS = {
  filtering: {
    name: 'filtering',
    fields: {
      searchKeyword: 'searchKeyword',
      activityFilter: 'activityFilter',
      countryId:'countryId',
      subscriptionPlanId:'subscriptionPlanId',
    }
  },
  sorting: {
    name: 'sorting',
    fields: {
      field: 'field',
      type: 'type',
    }
  },
  pagination: {
    name: 'pagination',
    fields: {
      page: 'page',
      pageSize: 'pageSize',
    }
  },
  isAssigned: 'isAssigned',
  roles: 'roles',
  lifeQuality: {
    clientPrimaryAccountId: 'clientPrimaryAccountId',
    lifeQualityQuestionType: 'lifeQualityQuestionType'
  }
};

export enum SortingType {
  asc = 'asc',
  desc = 'desc'
}

export enum ActivityFilter {
  active = 'active',
  all = 'all'
}