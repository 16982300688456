import {
  createMuiTheme,
  fade,
  TransitionsOptions,
} from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import Fade from '@material-ui/core/Fade';
import * as csstype from 'csstype';

export const colors = {
  // DON'T use #C12B20, use primaryAccent color instead
  primary: '#000',
  mainAccent: '#13A9BA',
  mainAccentHover: 'rgba(19,169,186,0.09)',
  primaryAccent: '#13A9BA',
  primaryAccentHover: '#DA1616',
  secondary: '#373633',
  red: '#DC3545',
  warning: '#FFC107',
  error: '#DC3545',
  black: '#000',
  black2: '#3D3F3D',
  grey: '#A9AAAC',
  greyLight: '#DFDFDF',
  greyLight2: '#C4C4C4',
  greyLight3: '#6C757D',
  greyLight4: '#b4b4b4',
  greyLight5: '#F8F9FA',
  greyLight6: '#D7D8D9',
  greyLight7: '#F1F1F1',
  greyDark: '#364859',
  greyDark2: '#8C8C8C',
  blueDark: '#071C4B',
  blue: '#236BF6',
  green: '#5FC97B',
  green2: '#16c79c',
  greenHover: '#6FCE88',
  white: '#FFFFFF',
  whitePale: '#FAFAFA',
  disabled: '#EFEFEF',
  backgroundColor: '#F7F7FA',
};

export const borderStyles = {
  primarySolid: `1px solid ${fade('#000', 0.3)}`,
  primarySolidHover: '1px solid transparent',
  secondarySolid: '',
  primaryDashed: `1px dashed ${fade('#000', 0.3)}`,
  primaryDashedHover: '1px dashed transparent',
  secondaryDashed: '',
};

const transitions: TransitionsOptions = {
  duration: {
    standard: 0.3,
  },
};

export const opacity = {
  disabled: 0.6,
};

const commonBorderStyles: CreateCSSProperties = {
  color: colors.black,
  transition: `${transitions.duration?.standard}s`,
  fontWeight: 500,
  cursor: 'pointer',
  lineHeight: 1.2,
};

export const shortcuts: { [name: string]: CreateCSSProperties } = {
  borderPrimarySolid: {
    ...commonBorderStyles,
    borderBottom: borderStyles.primarySolid,

    '&:hover': {
      borderBottom: borderStyles.primarySolidHover,
    },
  },
  borderPrimaryDashed: {
    ...commonBorderStyles,
    borderBottom: borderStyles.primaryDashed,

    '&:hover': {
      borderBottom: borderStyles.primaryDashedHover,
    },
  },
  disabled: {
    opacity: opacity.disabled,
    pointerEvents: 'none' as csstype.PointerEventsProperty,
  },
  errorText: {
    fontSize: 11,
    fontWeight: 500,
    width: '100%',
    color: colors.error,
    position: 'absolute' as csstype.PositionProperty,
  },
};

const fontFamily = '"Roboto", sans-serif';

const controlsRadius = 8;
const buttonRadius = 8;
const inputRadius = 4;
const spacing = 4;
const containersRadius = 8;
const circleRadius = '50%';

export const scrollStyles: CreateCSSProperties = {
  '&::-webkit-scrollbar': {
    width: 8,
    height: 8,
  },

  '&::-webkit-scrollbar-track': {
    borderRadius: containersRadius,
    backgroundColor: colors.greyLight7,
    margin: 1,
  },

  '&::-webkit-scrollbar-thumb': {
    background: colors.greyLight3,
    borderRadius: containersRadius,
  },

  '&::-webkit-scrollbar-thumb:hover': {
    background: colors.greyDark,
  },

  scrollbarColor: `${colors.greyLight3} ${colors.greyLight7}`,
  scrollbarWidth: 'thin',
};

export default createMuiTheme({
  spacing,
  opacity,
  shortcuts,
  transitions,
  borderStyles,
  radius: {
    control: controlsRadius,
    container: containersRadius,
    circle: circleRadius,
  },
  palette: {
    colors,
    primary: {
      main: colors.primaryAccent,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.error,
    },
  },
  typography: {
    fontFamily,
  },
  props: {
    MuiTooltip: {
      placement: 'top',
      TransitionComponent: Fade,
      TransitionProps: {
        exit: false,
      },
      leaveDelay: 0,
    },
    // MuiButton: {
    //   disableRipple: false,
    // },
    // MuiButtonBase: {
    //   disableRipple: true,
    // },
    // MuiIconButton: {
    //   disableRipple: true,
    // },
    // MuiTab: {
    //   disableRipple: true,
    // },
  },

  overrides: {
    MuiToolbar: {
      root: {
        paddingRight: 24,
        paddingLeft: 100,
      },
      regular: {
        minHeight: 56,
        '@media (min-width: 600px)': {
          minHeight: '56px',
        },
      },
    },

    MuiDrawer: {
      paper: {
        boxShadow: 'none',
      },
    },

    MuiButton: {
      root: {
        borderRadius: buttonRadius,
        fontWeight: 500,
        textTransform: 'none',

        boxSizing: 'border-box',

        '&$disabled': {
          opacity: 0.6,
        },
      },
      text: {
        '&:hover': {
          boxShadow: 'none',
          borderRadius: '25px',
        },
      },

      label: {
        fontWeight: 700,
      },
      sizeSmall: {
        height: 36,
        padding: `0 ${spacing * 4}px`,
        fontSize: 12,
      },
      sizeLarge: {
        height: 40,
        padding: `0 ${spacing * 6}px`,
        fontSize: 14,
      },
      contained: {
        // boxShadow: 'none',
        color: colors.greyDark,
        boxShadow: '0px 1px 13px rgba(162,162,162,0.58)',
        border: '1px solid #ffffff',

        '& .MuiButton-label':{
          fontWeight: 500,

        },
        '&:hover': {
          backgroundColor: colors.greyLight4,
          boxShadow: '0px 1px 13px rgba(19,169,186,0.26)',
        },
        '&$disabled': {
          color: fade(colors.greyDark, 0.5),
          backgroundColor: colors.disabled,
        },
      },
      containedPrimary: {
        backgroundColor: colors.primaryAccent,
        color: colors.white,
        boxShadow: '0px 1px 13px rgba(168,168,168,0.58)',

        '&:hover': {
          boxShadow: '0px 1px 13px rgba(19,169,186,0.26)',

          backgroundColor: colors.primaryAccent,
        },

        '&$disabled': {
          color: colors.whitePale,
          backgroundColor: colors.primaryAccent,
          opacity: 0.6,
        },
      },
      outlined: {
        borderColor: colors.black,
      },
      outlinedPrimary: {
        borderColor: colors.red,
        color: colors.red,

        '&:hover': {
          backgroundColor: colors.primaryAccentHover,
          borderColor: colors.primaryAccentHover,
          color: 'white',
        },

        '&$disabled': {
          borderColor: colors.red,
          color: colors.red,
          opacity: 0.6,
        },
      },
      containedSecondary: {
        backgroundColor: colors.green,

        '&:hover': {
          backgroundColor: colors.greenHover,
        },

        '&$disabled': {
          color: colors.whitePale,
          backgroundColor: colors.green,
          opacity: 0.6,
        },
      },
    },
    MuiTextField: {
      root: {
        position: 'relative',

        '& p': {
          // helper text
          marginTop: 0,
          position: 'absolute',
          top: '108%',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: colors.white,
        },
        '&$focused $notchedOutline': {
          borderColor: colors.black,
          borderWidth: 1,
        },
      },
      focused: {},
      notchedOutline: {
        top: 0,
        borderRadius: controlsRadius,
        borderColor: colors.black,
        backgroundColor: '#fff',

        '& legend': {
          display: 'none',
        },
      },
      input: {
        paddingTop: 0,
        paddingLeft: spacing * 5,
        paddingBottom: 0,
        height: 48,
      },
      inputMarginDense: {
        paddingTop: 0,
        paddingBottom: 0,
        height: 36,
      },
    },
    MuiInput: {
      root: {
        borderBottom: `1px solid ${colors.black}`,

        '&$error': {
          borderColor: colors.error,
        },
      },
      input: {
        padding: 0,
        height: '100%',

        '&::before, &::after': {
          borderBottom: 'none !important',
        },
      },
      underline: {
        '&::before, &::after': {
          borderBottom: 'none !important',
        },

        '&$disabled:before': {
          borderBottomStyle: 'solid',
          borderColor: colors.greyLight,
        },
      },
    },
    MuiInputBase: {
      root: {
        minHeight: 33,
        fontSize: 16,

        '&$disabled': {
          color: '#000',
          pointerEvents: 'none',
        },
        borderRadius: controlsRadius,
        border: controlsRadius,
      },
      input: {
        backgroundColor: colors.white,
        color: colors.black2,
        // border: `1px solid ${colors.grey}`,
        borderRadius: controlsRadius,
        // '&:focus': {
        //   border: `1px solid ${colors.primaryAccent}`
        // },

        // '&[aria-invalid="true"]:not(:focus)' : {
        //   border: `1px solid ${colors.red}`,
        // }
      },

      multiline: {
        height: 'auto',
      },
    },
    MuiInputAdornment: {
      positionStart: {
        margin: '0px',
        marginLeft: '0px',
        marginRight: '0px;',
        paddingLeft: '0px',
        '&:not(.MuiInputAdornment-hiddenLabel)': {
          marginTop: '0!important',
        },
        '& span': {
          position: 'absolute',
          left: '12px',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 11,
        color: colors.black,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: colors.white,
        borderRadius: inputRadius,
        border: `1px solid ${colors.grey}`,
        borderTopLeftRadius: inputRadius,
        borderTopRightRadius: inputRadius,
        '&$error': {
          color: 'white',
          border: `1px solid ${colors.red}`,
          borderRadius: inputRadius,
        },
        '&$focused': {
          color: 'white',
          borderRadius: inputRadius,
          backgroundColor: colors.white,
          '& .MuiInputAdornment-positionStart > svg': {
            color: colors.primaryAccent,
            fill: colors.primaryAccent,
          },
        },
        '&:hover': {
          backgroundColor: colors.white,
        },
      },
      error: {},
      focused: {},
      adornedStart: {
        paddingLeft: '0',
      },
      inputAdornedStart: {
        paddingLeft: '11px',
      },
      input: {
        padding: '0px',
      },
    },
    MuiSelect: {
      root: {
        borderRadius: controlsRadius,
      },
      select: {
        zIndex: 1,
        borderRadius: controlsRadius,

        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        zIndex: 1,
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.black,

        '&$checked': {
          color: colors.primaryAccent,
        },
      },
      checked: {},
      colorSecondary: {
        '&$checked': {
          color: colors.primaryAccent,
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: 'auto',
        padding: 0,

        '&$expanded': {
          minHeight: 'auto',
        },
      },
      content: {
        alignItems: 'center',
        margin: 0,
        width: '100%',
        padding: `0 0 0 ${spacing * 9}px`,

        '&$expanded': {
          margin: 0,
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        backgroundColor: colors.greyLight5,
        padding: spacing * 5,
        border: `1px solid ${colors.greyLight}`,
        boxShadow: 'none',

        '&$expanded': {
          margin: 0,

          '&:last-child': {
            marginBottom: 'initial',
          },
        },
      },
      rounded: {
        borderRadius: containersRadius,

        '&::before': {
          display: 'none',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        lineHeight: 1,
        ...shortcuts.errorText,
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiSnackbarContent: {
      root: {
        flexWrap: 'nowrap',
        backgroundColor: 'white',
        padding: spacing * 4,
        boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.16)',
      },
      message: {
        fontFamily,
        padding: 0,
        color: 'black',
        fontSize: 15,
        fontWeight: 500,
      },
      action: {
        paddingLeft: spacing * 4,
      },
    },
    MuiPaper: {
      elevation1: {
        // boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.16)',
        boxShadow: '0px 0px 10px rgb(160 160 160 / 28%)',
      },
      elevation3: {
        boxShadow: '0px 4px 12px rgba(91, 91, 91, 0.25)',
      },
      elevation4: {
        boxShadow: '0px 4px 12px rgba(91, 91, 91, 0.25)',
      },
      elevation8: {
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
      },
      rounded: {
        borderRadius: containersRadius,
      },
    },
    MuiSwitch: {
      root: {
        width: 30,
        height: 18,
        padding: 0,
      },
      switchBase: {
        padding: 0,
        top: 2,
        left: 3,

        '& > span': {
          width: '95%',
        },
      },
      colorSecondary: {
        color: 'white',
        '&$checked': {
          color: 'white',
          left: -7,
        },
        '&$checked + $track': {
          backgroundColor: colors.primaryAccent,
          opacity: 1,
        },
      },
      checked: {},
      track: {
        borderRadius: controlsRadius + 2,
      },
      thumb: {
        width: 14,
        height: 14,
        borderRadius: 10,
      },
    },
    MuiTable: {
      root: {
        borderRadius: containersRadius,
        overflow: 'hidden',
      },
    },
    MuiTableRow: {
      root: {
        '&$hover:hover': {
          backgroundColor: fade(colors.primaryAccent, 0.04),
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: `6px 0 6px ${spacing * 4}px`,
        color: colors.primary,
        fontSize: 15,
        height: 54,
        borderColor: colors.greyLight,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '&:last-of-type': {
          paddingRight: spacing * 4,
        },

        '& *': {
          whiteSpace: 'nowrap',
        },
      },
      head: {
        padding: `${spacing * 2}px ${spacing * 4}px`,
        backgroundColor: colors.greyLight5,
        height: 34,
        borderColor: colors.greyLight,
        borderWidth: '1px 0 1px 0',
        color: colors.greyDark,
        fontWeight: 400,
        whiteSpace: 'nowrap',
        borderStyle: 'none',
        borderBottom: `1px solid ${colors.greyLight}`,
        '&:first-child': {},

        '&:last-child': {},
      },
      stickyHeader: {
        backgroundColor: colors.greyLight5,
      },
    },
    MuiTablePagination: {
      root: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: spacing * 4,
        overflow: 'hidden',
      },
      toolbar: {
        border: `1px solid ${colors.greyLight}`,
        borderRadius: containersRadius,
        minHeight: 'auto',
        height: 42,
        backgroundColor: colors.greyLight5,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: colors.primaryAccent,

        '&:hover': {
          backgroundColor: colors.primaryAccent,
        },
      },
      current: {
        color: colors.primaryAccent,
      },
    },
    MuiFormControlLabel: {
      root: {
        color: colors.greyDark,
      },
    },
    MuiMenuItem: {
      root: {
        borderRadius: containersRadius,
        marginBottom: spacing,

        '&$selected': {
          backgroundColor: 'transparent',

          '&:hover': {
            backgroundColor: colors.greyLight5,
          },
        },
      },
    },

    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: colors.mainAccentHover,
          color: colors.mainAccent,
          borderRadius: 0,
          fontWeight: 500

          //       width: '97%',
          //       borderRadius: '0 8px 8px 0',
        },
      },
    },
    // MuiListItem: {
    //   root: {
    //     '&$selected': {
    //       backgroundColor: 'rgba(19, 169, 186, 0.09);',
    //       color: '#13A9BA',
    //       fontWeight: '500',
    //       width: '97%',
    //       borderRadius: '0 8px 8px 0',
    //       borderLeft: `2px solid  #13A9BA`,

    //       '& svg': {
    //         color: '#13A9BA',
    //         fill: '#13A9BA',
    //       }

    //      }
    //   },
    //   gutters: {
    //     paddingLeft: spacing * 2,
    //     paddingRight: spacing * 2
    //   },
    //   button: {
    //     '&:hover' :{
    //       width: '97%',
    //       borderRadius: '0 8px 8px 0',
    //     }
    //   }
    // },
    // MuiMenu: {
    //   list: {
    //     maxWidth: `calc(100% - ${spacing * 4}px)`,
    //     margin: `0 ${spacing * 2}px`
    //   },
    // },
    MuiRadio: {
      root: {
        color: colors.primaryAccent,

        '&$checked': {
          color: colors.primaryAccent,
        },
      },
      checked: {},
      colorSecondary: {
        '&$checked': {
          color: colors.primaryAccent,
        },
      },
    },
    MuiCard: {
      root: {
        backgroundColor: colors.greyLight5,
        border: `1px solid ${colors.greyLight}`,
        boxShadow: 'none',
        borderRadius: containersRadius,
        padding: spacing * 6,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        paddingTop: spacing * 5,
        display: 'block',
      },
    },
    MuiDialogActions: {
      root: {
        padding: `${spacing * 2}px ${spacing * 5}px ${spacing * 5}px`,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#000',
      },
    },
    MuiDialogContent: {
      root: {
        padding: spacing * 6,
      },
    },
  },
});
