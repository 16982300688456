import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './Envelope.styles';

interface EnvelopeIconProps extends Omit<SvgIconProps, 'classes'>, WithStyles<typeof styles> { }

const EnvelopeIcon: React.FC<EnvelopeIconProps> = ({ classes }) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" color="primary" classes={{ root: classes.root }}>
      <path d="M20.6266 4.84521H3.37345C3.21077 4.84541 3.05482 4.91013 2.93981 5.02518C2.8248 5.14024 2.76014 5.29622 2.76001 5.4589V6.69345C2.76001 6.75033 2.82169 6.81562 2.87209 6.84202L11.9407 12.0219C11.964 12.0352 11.9903 12.0421 12.017 12.0419C12.0444 12.0419 12.0713 12.0346 12.0948 12.0207L20.8894 6.84706C20.9388 6.81946 21.0698 6.7465 21.119 6.7129C21.1786 6.67234 21.24 6.63562 21.24 6.5629V5.45866C21.2398 5.29602 21.1751 5.1401 21.0601 5.0251C20.9451 4.9101 20.7892 4.84541 20.6266 4.84521Z" fill="currentColor" />
      <path d="M21.1634 8.55497C21.1398 8.5415 21.1131 8.5345 21.0859 8.53467C21.0587 8.53484 21.032 8.54217 21.0086 8.55593L16.0317 11.4842C16.0119 11.4957 15.9949 11.5116 15.9821 11.5306C15.9692 11.5496 15.9608 11.5713 15.9574 11.594C15.9541 11.6167 15.9559 11.6399 15.9627 11.6618C15.9696 11.6837 15.9813 11.7038 15.9969 11.7206L20.9745 17.0862C20.9888 17.1017 21.0061 17.1141 21.0254 17.1225C21.0447 17.131 21.0655 17.1353 21.0866 17.1352C21.1273 17.1351 21.1662 17.119 21.195 17.0902C21.2237 17.0615 21.2399 17.0225 21.24 16.9818V8.68817C21.2401 8.66119 21.233 8.63466 21.2196 8.61127C21.2062 8.58788 21.1868 8.56846 21.1634 8.55497Z" fill="currentColor" />
      <path d="M14.5976 12.4674C14.574 12.4416 14.5422 12.4248 14.5077 12.4196C14.4731 12.4145 14.4379 12.4213 14.4078 12.4391L12.4129 13.6129C12.296 13.6804 12.1636 13.7164 12.0286 13.7173C11.8936 13.7182 11.7607 13.6839 11.643 13.6179L9.88736 12.615C9.85902 12.5988 9.82628 12.5921 9.79387 12.5958C9.76147 12.5996 9.73108 12.6135 9.70712 12.6356L3.0404 18.8195C3.02293 18.8358 3.00951 18.856 3.00122 18.8784C2.99293 18.9009 2.98999 18.9249 2.99265 18.9487C2.99531 18.9725 3.00348 18.9953 3.01652 19.0154C3.02956 19.0354 3.04711 19.0522 3.06776 19.0643C3.17144 19.1252 3.27152 19.1543 3.37328 19.1543H20.45C20.4799 19.1543 20.5091 19.1456 20.5341 19.1292C20.5591 19.1127 20.5786 19.0893 20.5904 19.0619C20.6024 19.0345 20.6061 19.0043 20.601 18.9749C20.596 18.9455 20.5825 18.9183 20.5621 18.8965L14.5976 12.4674Z" fill="currentColor" />
      <path d="M8.24017 11.904C8.25783 11.8876 8.2714 11.8674 8.27979 11.8448C8.28818 11.8223 8.29115 11.7981 8.28846 11.7742C8.28578 11.7503 8.27751 11.7274 8.26432 11.7073C8.25114 11.6872 8.23341 11.6704 8.21257 11.6584L2.98873 8.67475C2.96542 8.66151 2.93904 8.65463 2.91223 8.65479C2.88542 8.65495 2.85913 8.66214 2.83597 8.67565C2.81281 8.68916 2.79361 8.70851 2.78028 8.73177C2.76695 8.75503 2.75996 8.78138 2.76001 8.80819V16.637C2.75984 16.6669 2.76845 16.6962 2.78475 16.7212C2.80106 16.7463 2.82436 16.766 2.85177 16.778C2.87918 16.7899 2.90949 16.7936 2.93895 16.7885C2.96841 16.7834 2.99573 16.7698 3.01753 16.7493L8.24017 11.904Z" fill="currentColor" />
    </SvgIcon>
  );
};

export default withStyles(styles)(EnvelopeIcon);