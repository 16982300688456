export interface AssessmentDTO {
  assessmentId: string;
  assignedAt: string;
  childId: string;
  clientPrimaryAccountId: string;
  clientPrimaryAccountFirstName: string;
  clientPrimaryAccountLastName: string;
  assessmentLastModifiedDate: string;
  submitted: string;
  subscriptionPlan: string;
  subscriptionPlanId: number;
  childName: string;
  lastName: string;
  country: string;
  chronologicalAgeYears: number;
  clientPrimaryAccountCreated: string;
  degreeOfNeurologicalFunction: string;
}

export class Assessment implements AssessmentDTO{
  assessmentId: string;
  childId: string;
  assignedAt: string;
  clientPrimaryAccountId: string;
  clientPrimaryAccountFirstName: string;
  clientPrimaryAccountLastName: string;
  assessmentLastModifiedDate: string;
  submitted: string;
  subscriptionPlan: string;
  subscriptionPlanId: number;
  childName: string;
  lastName: string;
  country: string;
  chronologicalAgeYears: number;
  clientPrimaryAccountCreated: string;
  degreeOfNeurologicalFunction: string;

  constructor(dto: AssessmentDTO) {
    this.update(dto);
  }

  get asJson(): AssessmentDTO {
    return {
      assessmentId: this.assessmentId,
      childId: this.childId,
      assignedAt: this.assignedAt,
      clientPrimaryAccountId: this.clientPrimaryAccountId,
      clientPrimaryAccountFirstName: this.clientPrimaryAccountFirstName,
      clientPrimaryAccountLastName: this.clientPrimaryAccountLastName,
      submitted: this.submitted,
      assessmentLastModifiedDate: this.assessmentLastModifiedDate,
      subscriptionPlan: this.subscriptionPlan,
      subscriptionPlanId: this.subscriptionPlanId,
      childName: this.childName,
      lastName: this.lastName,
      country: this.country,
      chronologicalAgeYears: this.chronologicalAgeYears,
      clientPrimaryAccountCreated: this.clientPrimaryAccountId,
      degreeOfNeurologicalFunction: this.degreeOfNeurologicalFunction,
    };
  }

  update(newData: Partial<AssessmentDTO>): AssessmentDTO {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
