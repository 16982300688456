import { injectable } from 'inversify';
import BaseService from '@core/services/base';
import { UserAccount, UserAccountDTO } from '@shared/models/useraccount';
import { ListRequestParams, ListResponse } from '@shared/types/services';
import Axios, { AxiosInstance } from 'axios';
import container from '@core/di';
import HttpClient from '@core/HttpClient';
import { INSTANCES } from '@core/HttpClient/httpClient';
import qs from 'qs';
import { SortingType } from '@shared/constants/services';

const STATUSES = {
  Enabled: 'Active',
  Disabled: 'Deactivated',
  Active: 'Enabled',
  Deactivated: 'Disabled',
};

export interface GetAccountParams {
  Page?: number;
  PageSize?: number;
  'Sort.Field'?: string;
  'Sort.Type'?: SortingType;
  Role?: string;
  Roles?:  string[];
  Status?: string;
  SearchKeyword?: string;
}

@injectable()
export default class UserAccountsService extends BaseService<
  UserAccountDTO,
  UserAccount
> {
  static diToken = Symbol('useraccounts-service');
  private httpClient: AxiosInstance;

  constructor() {
    super({
      domainArea: 'user-accounts',
      Model: UserAccount,
      collection: 'useraccounts',
    });
    this.httpClient = container
      .get<HttpClient>(HttpClient.diToken)
      .getInstance(INSTANCES.SSO_INSTANCE);
  }

  statusMatching(dto) {
    dto.status = STATUSES[dto.status] || dto.status;

    return dto;
  }

  private getAccountsListParams(params: ListRequestParams): GetAccountParams {
    return {
      'Sort.Field': params.sorting?.field,
      'Sort.Type': params.sorting?.type,
      Page: params.pagination?.page,
      PageSize: params.pagination?.pageSize,
      Role: typeof params.filtering?.role === 'string' ? params.filtering?.role : undefined,
      Roles: Array.isArray(params.filtering?.role) ? params.filtering?.role : undefined,
      SearchKeyword: params.filtering?.searchKeyword || undefined,
      Status: params.filtering?.status || undefined,
    };
  }

  async getAccountsList(params: ListRequestParams) {
    try {
      const { data } = await Axios.get<ListResponse<UserAccountDTO>>(this.urlPrefix, {
        params: this.getAccountsListParams(params),
        paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' })
      });

      const { items, pageInfo } = data;

      return {
        pageInfo,
        items: items.map((dto) => new this.Model(this.statusMatching(dto))),
      };
    } catch (err) {
      throw err;
    }
  }

  changeRole(ssoId: string, data) {
    return Axios.put(this.getURL(`${ssoId}/role`), data);
  }

  getAllClinicians() {
    return Axios.get(this.getURL(`clinicians`));
  }

  deactivateAccount(ssoId: string) {
    return Axios.put(this.getURL(`${ssoId}/disable`));
  }

  activateAccount(ssoId: string) {
    return Axios.put(this.getURL(`${ssoId}/enable`));
  }

  async reinviteUser(ssoId: string) {
    const { data } = await this.httpClient.post(`users/${ssoId}/reinvite`, {
      returnUrl: '#',
    });

    return data;
  }

}
