import { Container } from 'inversify';
import { FamiliesService, AuthService } from 'src/shared/services';
import { FamiliesStore, AuthStore } from 'src/shared/stores';
import Config from '@core/config';
import AuthManager from '@core/AuthManager';
import HttpClient from '@core/HttpClient';
import UserAccountsService from 'src/shared/services/useraccounts';
import UserAccountStore from 'src/shared/stores/useraccounts';
import AssessmentsHistoryService from 'src/shared/services/assessmentshistory';
import AssessmentsHistoryStore from 'src/shared/stores/assessmentshistory';
import ProfileService from '@shared/services/profile';
import ProfileStore from '@shared/stores/profile';
import NotificationStore from '@shared/stores/notifivation';
import NotificationService from '@shared/services/notification';
import AssessmentsStore from '@shared/stores/assessments';
import AssessmentsEvaluationsService from '@shared/services/assessmentsevaluations';
import ClientPrimaryService from '@shared/services/client-primary';
import AssessmentEvaluationStore from '@shared/stores/assessmentsevaluations';
import AssessmentsService from '@shared/services/assessments';
import LifequalityService from '@shared/services/lifequality';
import LifeQualityStore from '@shared/stores/lifequality';
import InterventionsService from '@shared/services/interventions';
import InterventionsStore from '@shared/stores/interventions';
import SubscriptionsStore from '@shared/stores/subscriptions';
import SubscriptionsService from '@shared/services/subscriptions';
import * as clientStores from '@Client/shared/stores';
import * as clientServeces from '@Client/shared/services';
import ClinicianService from '@shared/services/clinician';
import ClinicianStore from '@shared/stores/clinician';
import TreatmentPlansService from '@shared/services/treatmentplans';
import TreatmentPlansStore from '@shared/stores/treatmentplans';
import { AssessmentModelFactory } from '@Admin/factories/AssessmentModelFactory';
import { FeaturesPermissionFactory } from '@Admin/factories/FeaturesPermissionFactory';
import AuditService from '@shared/services/audit';

const container = new Container();

const coreContainters = [
  Config,
  HttpClient,
  AuthStore,
  AuthService,
  AuthManager,
];

// to index @Admin/stores/index.ts
// to index @Admin/serveices/index.ts
const adminContainers = [
  ProfileService,
  ProfileStore,
  FamiliesService,
  FamiliesStore,
  UserAccountsService,
  UserAccountStore,
  AssessmentsHistoryStore,
  AssessmentsHistoryService,
  NotificationStore,
  NotificationService,
  AssessmentsStore,
  AssessmentsEvaluationsService,
  ClientPrimaryService,
  AssessmentEvaluationStore,
  AssessmentsService,
  LifequalityService,
  LifeQualityStore,
  InterventionsService,
  InterventionsStore,
  SubscriptionsStore,
  SubscriptionsService,
  ClinicianStore,
  ClinicianService,
  TreatmentPlansStore,
  TreatmentPlansService,
  AssessmentModelFactory,
  FeaturesPermissionFactory,
  AuditService
];

const clientContainers = [
  ...Object.values(clientStores),
  ...Object.values(clientServeces),
];

const instances = [
  ...coreContainters,
  ...adminContainers,
  ...clientContainers,
];

instances.forEach((instance: InstanceType<any>) => {
  container.bind(instance.diToken).to(instance).inSingletonScope();
});

export default container;
