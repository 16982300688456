import {injectable} from 'inversify';
import Axios, {AxiosInstance, AxiosRequestConfig} from 'axios';

interface Config {
  baseUrl: string;
  apiSSOUrl: string;
  getUserTokens: () => Promise<{ refresh: any; access: any; }>;
  handleSignIn: () => void;
}

export enum INSTANCES {
  API_INSTANCE = 'API_INSTANCE',
  SSO_INSTANCE = 'SSO_INSTANCE'
}

@injectable()
export default class HttpClient {
  static diToken = Symbol('httpClient');

  private getAccessToken: Config['getUserTokens'];
  private config: Config;

  // private instance: AxiosInstance;
  private instances: AxiosInstance[] =[];
 
  initialize(config: Config) {
    // Default axios
    Axios.defaults.baseURL = config.baseUrl;

    // Additional API instances
    this.instances[INSTANCES.API_INSTANCE] = Axios.create({
      baseURL: config.baseUrl,
    });

    this.instances[INSTANCES.SSO_INSTANCE] = Axios.create({
      baseURL: config.apiSSOUrl,
    });

    this.getAccessToken = config.getUserTokens;
    this.config = config;

    this.configureInstances();
  }

  private configureInstances() {

    Axios.defaults.headers = {
      'Content-Type': 'application/json',
    };

    this.instances[INSTANCES.API_INSTANCE].defaults.headers = {
      'Content-Type': 'application/json',
    };

    this.instances[INSTANCES.SSO_INSTANCE].defaults.headers = {
      'Content-Type': 'application/json',
    };

    this.setRequestInterceptors();
    this.setResponseInterceptors();
  }
  
  private async getAuthHeader() {
    const tokens = await this.getAccessToken();

    return tokens.access ? `Bearer ${tokens.access}` : '';
  }

  private setRequestInterceptors() {
    Axios.interceptors.request.use(
      async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        const authHeader = await this.getAuthHeader();

        if (authHeader) {
          config.headers.Authorization = authHeader;
        }

        return config;
      },
      (error: Error): Error => {
        throw error;
      }
    );

    this.instances[INSTANCES.API_INSTANCE].interceptors.request.use(
      async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {

        const authHeader = await this.getAuthHeader();

        if (authHeader) {
          config.headers.Authorization = authHeader;
        }

        return config;
      },
      (error: Error): Error => {
        throw error;
      }
    );

    this.instances[INSTANCES.SSO_INSTANCE].interceptors.request.use(
      async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        const authHeader = await this.getAuthHeader();

        if (authHeader) {
          config.headers.Authorization = authHeader;
        }

        return config;
      },
      (error: Error): Error => {
        throw error;
      }
    );
  }

  private setResponseInterceptors() {
    Axios.interceptors.response.use(
      (response) => ({...response, data: response.data.data || response.data}),
      async (error) => {
        const response = error ? error.response : undefined;

        if (!response) {
          return;
        }

        if (response.status === 403) {
          this.processForbidden();
        }

        if (response.status === 401) {
          this.config.handleSignIn();
        }

        if (response.status >= 500) {
          // TODO: show message
        }

        throw error;
      }
    );

    this.instances[INSTANCES.API_INSTANCE].interceptors.response.use(
      (response) => ({...response, data: response.data.data || response.data}),
        (error) => {
        const response = error ? error.response : undefined;

        if (response?.status === 401) {
          this.config.handleSignIn();
        }

        throw error;
      }
    );
  }

  private processForbidden(msg = '', showMessage = true) {
    if (showMessage) {
      // showNotification(msg);
    }
  }

  getInstance(type: INSTANCES = INSTANCES.API_INSTANCE) {
    return this.instances[type];
  }

  get() {
    return this.instances[INSTANCES.API_INSTANCE];
  }

}
