import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './SubscriptionManagement.styles';

interface SubscriptionManagementProps
  extends Omit<SvgIconProps, 'classes'>,
    WithStyles<typeof styles> {}

const SubscriptionManagement: React.FC<SubscriptionManagementProps> = ({
  classes,
}) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      classes={{ root: classes.root }}
    >
      <rect width="24" height="24" rx="6" fill="#F7F7FA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.993 5C8.129 5 5 8.136 5 12C5 15.864 8.129 19 11.993 19C15.864 19 19 15.864 19 12C19 8.136 15.864 5 11.993 5ZM17.229 10.012L13.722 9.71101L12.322 6.41401C14.569 6.54701 16.459 8.00301 17.229 10.012ZM11.9999 13.386L13.6799 14.394L13.2319 12.483L14.7089 11.202L12.7629 11.034L11.9999 9.24204L11.2369 11.041L9.29092 11.209L10.7679 12.49L10.3269 14.394L11.9999 13.386ZM11.678 6.41401L10.278 9.71801L6.76401 10.019C7.53401 8.00301 9.42401 6.54001 11.678 6.41401ZM6.561 10.6979C6.456 11.1179 6.4 11.5519 6.4 11.9999C6.4 13.7359 7.191 15.2899 8.437 16.3189L9.214 12.9939L6.561 10.6979ZM9.08806 16.774L12.0001 15.017L14.9121 16.767C14.0581 17.292 13.0641 17.6 11.9931 17.6C10.9291 17.6 9.93506 17.292 9.08806 16.774ZM14.786 12.9939L15.563 16.3189C16.802 15.2899 17.6 13.7359 17.6 11.9999C17.6 11.5519 17.537 11.1109 17.439 10.6979L14.786 12.9939Z"
        fill="#13A9BA"
      />
    </SvgIcon>
  );
};

export default withStyles(styles)(SubscriptionManagement);
