import {injectable} from 'inversify';
import {Family, FamilyDTO} from '@shared/models/family';
import BaseService from '@core/services/base';
import container from '@core/di';
import AuthManager from '@core/AuthManager';
import {authResponse} from '@shared/stores/auth';

interface UserManagerActions {
  onUserLoaded : (user : authResponse) => void;
}

@injectable()
export default class AuthService extends BaseService<FamilyDTO, Family> {
  static diToken = Symbol('auth-service');
  private userManager;

  constructor() {
    super({domainArea: 'auth', Model: Family});
    this.userManager = container.get<AuthManager>(AuthManager.diToken).get();
  }

  handleLogin(params: Record<string, any>) {
    this.userManager.signinRedirect({
      extraQueryParams: params,
    });
  }

  signinRedirectCallback() {
    return this.userManager.signinRedirectCallback();
  }

  logout() {
    return this.userManager.signoutRedirect();
  }

  reset() {
    this.userManager.clearStaleState();
    this.userManager.removeUser();
  }

  public getUser() {
    return this.userManager.getUser();
  }

  public initUserManagerActions(userManagerActions: UserManagerActions) {
    this.userManager.events.addUserLoaded(userManagerActions.onUserLoaded);
  }
}
