import { injectable } from 'inversify';

import container from '@core/di';
import BaseStore from '@core/stores/base';
import { action } from 'mobx';
import {
  AssessmentListItem,
  AssessmentListItemDTO,
} from '@shared/models/assesmentlistitem';
import { ClientSubscriptionsService } from '../services';
import { ActivateSubscription } from '../models/activatesubscription';

@injectable()
export default class ClientSubscriptionsStore extends BaseStore<
  AssessmentListItemDTO,
  AssessmentListItem,
  ClientSubscriptionsService
> {
  static diToken = Symbol('client-subscriptions-store');

  constructor() {
    super({
      service: container.get<ClientSubscriptionsService>(
        ClientSubscriptionsService.diToken
      ),
    });

    this.loading = {
      list: false,
      item: false,
    };
  }

  @action createSubscription = async (
    subscriptionDetails: ActivateSubscription
  ) => {
    return   this.service.createSubscription(subscriptionDetails);
  };

  @action updateSubscription = async (
    subscriptionDetails: ActivateSubscription
  ) => {
    return   this.service.updateSubscription(subscriptionDetails);
  };
}
