import { injectable } from 'inversify';
import container from '@core/di';
import BaseStore from '@core/stores/base';
import { action, computed, observable } from 'mobx';
import { Intervention, InterventionDTO, InterventionVideoUpdate } from '@shared/models/interventions';
import InterventionsService from '@shared/services/interventions';
import { InterventionVideo } from '@shared/models/interventionvideos';
import { InterventionFile } from '@shared/models/interventionfiles';

@injectable()
export default class InterventionsStore extends BaseStore<
  InterventionDTO,
  Intervention,
  InterventionsService
> {
  static diToken = Symbol('interventions-store');
  @observable private _therapeuticInterventions: Intervention[];
  @observable private _interventionVideos: InterventionVideo[];
  @observable private _interventionFiles: InterventionFile[];

  constructor() {
    super({
      service: container.get<InterventionsService>(
        InterventionsService.diToken
      ),
    });

    this.loading = {
      list: false,
      item: false,
    };
  }

  @action fetchTherapeuticInterventions = async () => {
    const therapeuticInterventions = await this.service.getTerapeuticInterventions();
    this._therapeuticInterventions = therapeuticInterventions;

    return therapeuticInterventions;
  };

  @computed get therapeuticInterventions(): Intervention[] {
    return this._therapeuticInterventions;
  }
  
  @computed get interventionVideos(): InterventionVideo[] {
    return this._interventionVideos;
  }

  @computed get interventionFiles(): InterventionFile[] {
    return this._interventionFiles;
  }

  @computed get therapeuticInterventionsMenuItems() {
    if (this._therapeuticInterventions) {
      return this._therapeuticInterventions.map(({ id, name }) => ({
        id,
        title: name,
      }));
    }

    return [];
  }

  // TODO: REFACTOR!!!
  @action videoTags = (id) => {
    if (this._therapeuticInterventions) {

      const item =  this._therapeuticInterventions.filter(
        (intervention) => intervention.id == id
      );

      return item[0].treatmentOptions;

    }

    return [];
  };

  // videos
  @action getVideos = async (id) => {
    const interventionVideos = await this.service.getVideos(id);
    this._interventionVideos = interventionVideos;

    return interventionVideos;
  };

  @action uploadVideo = async (id, formData, setProgress) => {
    return this.service.uploadVideo(id, formData, setProgress);
  };

  @action updateVideo = async (interventionId, videoId,  data: InterventionVideoUpdate) => {
    return this.service.updateVideo(interventionId, videoId,  data);
  };

  @action deleteVideo = async (interventionId, videoId) => {
    return await this.service.deleteVideo(interventionId, videoId);
  };
  
  // files
  @action getFiles = async (id) => {
    const interventionFiles = await this.service.getFiles(id);
    this._interventionFiles = interventionFiles;

    return interventionFiles;
  };

  @action uploadFile = async (id, formData, setProgress) => {
    return this.service.uploadFile(id, formData, setProgress);
  };

  @action updateFile = async (interventionId, videoId,  data: InterventionVideoUpdate) => {
    return this.service.updateFile(interventionId, videoId,  data);
  };

  @action deleteFile = async (interventionId, videoId) => {
    return await this.service.deleteFile(interventionId, videoId);
  };

  @action cancelUpload =  () => {
    return  this.service.cancelUpload();
  };
}
