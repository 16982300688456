import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  progressRoot: {
    height: 10,
    borderRadius: 0,
  },
  colorPrimary: {
    backgroundColor: '#f7f7fa',
  },
  bar: {
    borderRadius: 0,
    backgroundColor: '#13a9ba',
  },
});

export default function Progress() {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 100;
        }
        const diff = Math.random() * 10;

        return Math.min(oldProgress + diff, 100);
      });
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.root}>
      <LinearProgress
        variant="determinate"
        value={progress}
        classes={{
          root: classes.progressRoot,
          bar: classes.bar,
          colorPrimary: classes.colorPrimary,
        }}
      />
    </div>
  );
}
