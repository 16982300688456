import * as React from 'react';
import { hot } from 'react-hot-loader';
import { withStyles, WithStyles } from '@material-ui/core';
import { Route, withRouter, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styles from './Admin.styles';
import container from '@core/di';
import { AuthStore } from '@shared/stores';
import { AuditService } from '@shared/services';
import PrivateModule from './modules/Private/Private';
import PublicModule from './modules/Public/Public';
import Loading from '@shared/components/Loading';
import Signout from './modules/Private/submodules/Signout';
import Button from '@shared/components/Button';
import ROUTES from './shared/constants/routes';

export interface AppProps extends WithStyles<typeof styles>, RouteComponentProps {}

@hot(module)
@observer
class Admin extends React.Component<AppProps> {
  @observable private appCrashed: boolean;
  @observable private isIdentify = false;

  private authStore = container.get<AuthStore>(AuthStore.diToken);
  private auditorService = container.get<AuditService>(AuditService.diToken);

  private isCurrentRoutePrivate = () => {
    const allPrivateRoutes = Object.values(ROUTES.private);
    const { pathname } = this.props.location;
    const isCurrentRoutePrivate = (
      allPrivateRoutes.some((route) => this.props.location.pathname.startsWith(route)) && 
      pathname !== ROUTES.private.signoutOidc
    );

    return isCurrentRoutePrivate;
  }

  private handleUserIdentify = async () => {
    if (this.authStore.loggedIn && !this.isIdentify) {
      await this.auditorService.userIdentify();
      this.isIdentify = true;
    }

    if (this.authStore.loggedIn === false && this.isCurrentRoutePrivate()) {
      const { pathname, search } = this.props.location;
      this.authStore.setLoginRedirectURL(`${pathname}${search}`);
    }
  };

  componentDidUpdate() {
    this.handleUserIdentify();
  }

  componentDidCatch() {
    this.appCrashed = true;
  }

  render() {
    const { loggedIn, restrictedRoleLoggedIn } = this.authStore;

    if (this.appCrashed) {
      return <div>App Crash</div>;
    }

    if (restrictedRoleLoggedIn) {
      const { classes } = this.props;

      return (
        <Signout
          type="withMessage"
          message={(
            <div className={classes.restrictedMessage}>
              <h6 className={classes.restrictedMessageTitle}>Permissions denied. Please try different credentials</h6>
              <Button text="Logout" onClick={this.authStore.logout} />
            </div>
          )}
        />
      );
    }

    if (loggedIn === undefined) {
      return null;
    }

    if (loggedIn && !this.isIdentify) {
      return  <Loading />;
    }

    return <Route path="/" component={loggedIn ? PrivateModule : PublicModule} />;
  }
}

export default withStyles(styles)(withRouter(Admin));