import { ROLES } from '@shared/models/employee';

export enum FEATURES {
  AssignReassign = 'AssignReassign',
  UpdateTreatmentPlanModel = 'UpdateTreatmentPlanModel',
  ShowClientPrimaryAccount = 'ShowClientPrimaryAccount',
}

export const featurePermissionMatrix = [
  { featureName: FEATURES.AssignReassign, roles: [ROLES.SeniorClinician, ROLES.MasterClinician] },
  { featureName: FEATURES.UpdateTreatmentPlanModel, roles: [ROLES.Admin, ROLES.SuperAdministrator] },
  { featureName: FEATURES.ShowClientPrimaryAccount, roles: [ROLES.SeniorClinician, ROLES.MasterClinician, ROLES.Clinician] },
];
