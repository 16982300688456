import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './ClientManagement.styles';

interface ClientManagementProps
  extends Omit<SvgIconProps, 'classes'>,
    WithStyles<typeof styles> {}

const ClientManagement: React.FC<ClientManagementProps> = ({ classes }) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      classes={{ root: classes.root }}
    >
      <rect width="24" height="24" rx="6" fill="#F7F7FA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.88 6.33333H16.6667C17.4 6.33333 18 6.93333 18 7.66667V17C18 17.7333 17.4 18.3333 16.6667 18.3333H7.33333C7.24 18.3333 7.15333 18.3267 7.06667 18.3133C6.80667 18.26 6.57333 18.1267 6.39333 17.9467C6.27333 17.82 6.17333 17.68 6.10667 17.52C6.04 17.36 6 17.18 6 17V7.66667C6 7.48 6.04 7.30667 6.10667 7.15333C6.17333 6.99333 6.27333 6.84667 6.39333 6.72667C6.57333 6.54667 6.80667 6.41333 7.06667 6.36C7.15333 6.34 7.24 6.33333 7.33333 6.33333H10.12C10.4 5.56 11.1333 5 12 5C12.8667 5 13.6 5.56 13.88 6.33333ZM8.66669 8.99992H15.3334V10.3333H8.66669V8.99992ZM15.3334 11.6667H8.66669V13.0001H15.3334V11.6667ZM13.3334 14.3333H8.66669V15.6666H13.3334V14.3333ZM12.0004 6.16669C12.2737 6.16669 12.5004 6.39336 12.5004 6.66669C12.5004 6.94002 12.2737 7.16669 12.0004 7.16669C11.727 7.16669 11.5004 6.94002 11.5004 6.66669C11.5004 6.39336 11.727 6.16669 12.0004 6.16669ZM7.33346 17H16.6668V7.66669H7.33346V17Z"
        fill="#13A9BA"
      />
    </SvgIcon>
  );
};

export default withStyles(styles)(ClientManagement);
