import { injectable } from 'inversify';
import container from '@core/di';
import BaseStore from '@core/stores/base';
import { action, computed, observable } from 'mobx';
import NotificationService from '@shared/services/notification';
import { Alert, AlertDTO, ALERT_TYPE } from '@shared/models/alert';

@injectable()
export default class NotificationStore extends BaseStore<
  AlertDTO,
  Alert,
  NotificationService
> {
  static diToken = Symbol('notification-store');
  @observable private _alert: Alert | null;

  constructor() {
    super({
      service: container.get<NotificationService>(NotificationService.diToken),
    });
  }

  @computed get alert(): Alert | null {
    return this._alert;
  }

  @action clearAlert = () => {
    this._alert = null;
  }

  @action alertMessage = (message: string, type = ALERT_TYPE.WARNING) => {
    this._alert = new Alert({
        type,
        message
    });
  }

}
