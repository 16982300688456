import { createStyles, Theme, fade } from '@material-ui/core';

const drawerWidthOpen = 200;
const drawerWidthClose = 48;
const toolbarHeight = 80;
const itemHeight = 52;

export default ({
  palette: { colors },
  spacing,
  transitions,
  mixins,
  radius,
}: Theme) => {
  const listItemBase = {
    borderLeft: `2px solid transparent`,
    borderRadius: `0 ${radius.control}px ${radius.control}px 0`,
    '& $listItemTextRoot': {
      color: 'black',
      fontWeight: 'normal',
      fontSize: 12,
      height: '100%',
      paddingBottom: '20px'
      // lineHeight: `${itemHeight}px`,
    },
  };

  const listItemBaseHover = {
    color: colors.primaryAccent,
    backgroundColor: colors.mainAccentHover,
    borderLeft: `2px solid ${colors.primaryAccent}`,

    '& $listItemTextRoot': {
      color: colors.primaryAccent,
      fontWeight: 500,
    },
  };

  return createStyles({
    '@global': {
      '.MuiBadge-colorPrimary': {
        color: '#ffffff',
        backgroundColor: '#EFAE05',
      }
    },
    root: {
      position: 'relative',
    },
    rootOpen: {
      width: drawerWidthOpen,

      '& $menuButton': {
        opacity: 0,
      },

      '&:hover': {
        '& $menuButton': {
          opacity: 1,
        },
      },
    },
    rootClose: {
      width: drawerWidthClose,
    },
    menuButton: {
      color: 'black',
      position: 'absolute',
      right: -12,
      boxShadow: '0px 4px 8px rgba(68, 68, 68, 0.16)',
      backgroundColor: 'white',
      fontSize: 24,
      padding: 0,
      zIndex: 3,
      transition: `${transitions.duration.standard}s`,

      '&:hover': {
        backgroundColor: 'white',
      },
    },
    drawer: {
      display: 'block',
      height: '100%',
    },
    drawerOpen: {
      width: drawerWidthOpen,
      border: 'none',
      overflow: 'visible',
      transition: transitions.create('width', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      width: drawerWidthClose,
      border: 'none',
      overflow: 'visible',
      transition: transitions.create('width', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
    },
    logo: {
      width: 154,
      height: 'auto',
    },
    logoSmall: {
      width: 16,
      height: 'auto',
    },
    toolbar: {
      overflow: 'hidden',
      padding: spacing(0, 3, 0, 1),
      background: colors.greyLight5,
      boxShadow: '2px 0px 4px rgba(0, 0, 0, .08)',
      ...mixins.toolbar,
      height: toolbarHeight,
      borderBottom: `1px solid ${fade(colors.greyDark, 0.08)}`,
      boxSizing: 'content-box',
    },
    content: {
      flexGrow: 1,
      flex: 'auto',
      // background: colors.greyLight5,
      // boxShadow: '2px 0px 4px rgba(0, 0, 0, .08)',
      overflowY: 'auto',
      overflowX: 'hidden',
      height: 'calc(100vh - 136px)',
      paddingTop: spacing(3),

      '&::-webkit-scrollbar': {
        width: 0,
      },
    },
    list: {
      width: '100%',
      paddingTop: 0,
    },
    listItem: {
      padding: 0,
      cursor: 'default',

      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    listItemIcon: {
      // backgroundColor: colors.primaryAccent,
      borderRadius: radius.control,
      minWidth: 24,
      marginRight: '17px',

      '& svg': {
        fontSize: 24,
      },
    },
    listItemText: {
      marginTop: '15px',
      margin: 0,
      width: '135px',
      paddingBottom: '5px'
    },
    listItemTextRoot: {
      fontSize: 15,
    },
    navLink: {
      padding: '0 0 0 23px',
      height: itemHeight,
      cursor: 'pointer',
      ...listItemBase,

      '&:hover': {
        ...listItemBaseHover,
      },
    },
    activeRouteClass: {
      cursor: 'default',
      pointerEvents: 'none',
      ...listItemBaseHover,
    },
    flex: {
      display: 'flex',
    },
    flexColumn: {
      flexDirection: 'column',
    },
    alignItemsCenter: {
      alignItems: 'center',
    },
    justifyContentCenter: {
      justifyContent: 'center',
    },
    fullWidth: {
      width: '100%',
    },
    minHeight: {
      minHeight: itemHeight,
    },
    ellipsisText: {
      whiteSpace: 'normal',
    },
    topPadding: {
      height: 80,
    },
  });
};
