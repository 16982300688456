import { injectable } from 'inversify';

import BaseService from '@core/services/base';
import { Profile, ProfileDTO } from '@shared/models/profile';

@injectable()
export default class NotificationService extends BaseService<ProfileDTO, Profile> {
  static diToken = Symbol('natification-service');

  constructor() {
    super({ domainArea: 'user-accounts', Model: Profile });
  }

}
