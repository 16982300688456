import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { AuthStore } from '@shared/stores';
import Lazyload from '@shared/components/LazyLoad';
import {Redirect, Route, Switch, RouteProps, useLocation} from 'react-router-dom';
import container from '@core/di';
import ROUTES from '@Admin/shared/constants/routes';
import ProfileMenu from './submodules/TopBar/components/ProfileMenu';
import NavigationMenu from './submodules/NavigationMenu';
import { useStyles } from './Private.styles';
import ClinicianStore from '@shared/stores/clinician';
import Helmet from 'react-helmet';

const Families = React.lazy(() => import('./submodules/Families'));
const FamilyTreatmentPlanReview = React.lazy(() => import('./submodules/Families/pages/FamilyInfo/components/ApprovedTreatmentPlans/components/ReviewApprovedPlan/ReviewApprovedPlan'));
const Materials = React.lazy(() => import('./submodules/Materials'));
const Employee = React.lazy(() => import('./submodules/Employee'));
const Signout = React.lazy(() => import('./submodules/Signout'));
const Profile = React.lazy(() => import('./submodules/Profile'));
const Assessments = React.lazy(() => import('./submodules/Assessments'));
const ContentManagement = React.lazy(() => import('./submodules/ContentManagement'));
const SubscriptionManagement = React.lazy(() => import('./submodules/SubscriptionManagement'));
const NewClientAssignments = React.lazy(() => import('./submodules/NewClientAssignments'));

const ReviewAssessment = React.lazy(() =>
  import('./submodules/AssessmentTreatmentPlanReview/AssessmentTreatmentPlanContainer/components/ReviewAssessment/ReviewAssessment'));
const AssessmentTreatmentPlanReview = React.lazy(() => import('./submodules/AssessmentTreatmentPlanReview'));
const AssessmentSummary = React.lazy(() => import('./submodules/Families/pages/FamilyInfo/components/AssessmentsTab/AssessmentSummary/AssessmentSummary'));
const StartAssessment = React.lazy(() => import('./submodules/Families/pages/FamilyInfo/components/AssessmentsTab/StartAssessment/StartAssessment'));
const ReviewTreatmentPlan = React.lazy(() => import('./submodules/AssessmentTreatmentPlanReview/AssessmentTreatmentPlanContainer/components/ReviewTreatmentPlan/ReviewTreatmentPlan'));
const RegenerateTreatmentPlan = React.lazy(() => import('./submodules/AssessmentTreatmentPlanReview/AssessmentTreatmentPlanContainer/components/RegeneratedTreatmentPlan/RegeneratedTreatmentPlan'));
const ShowDetailsTreatmentPlan = React.lazy(() => import('./submodules/AssessmentTreatmentPlanReview/AssessmentTreatmentPlanContainer/components/ShowDetailsTreatmentPlan/ShowDetailsTreatmentPlan'));

const routes = (): Array<RouteProps> => {
  const {
    employee,
    families,
    familyTreatmentPlanReview,
    materials,
    signoutOidc,
    profile,
    assessments,
    content,
    subscription,
    client,
    assessmentTreatmentTabs,
    assessmentTreatmentReview,
    assessmentTreatmentPlanReview,
    assessmentSummary,
    startAssessment,
    assessmentTreatmentRegeneration,
    assessmentTreatmentShowDetails
  } = ROUTES.private;

  return [
    {path: employee, component: Employee},
    {path: families, component: Families},
    {path: familyTreatmentPlanReview, component: FamilyTreatmentPlanReview},
    {path: materials, component: Materials},
    {path: signoutOidc, component: Signout},
    {path: profile, component: Profile},
    {path: assessments, component: Assessments},
    {path: content, component: ContentManagement},
    {path: subscription, component: SubscriptionManagement},
    {path: client, component: NewClientAssignments},
    {path: assessmentTreatmentReview, component: ReviewAssessment},
    {path: assessmentTreatmentPlanReview, component: ReviewTreatmentPlan},
    {path: assessmentSummary, component: AssessmentSummary },
    {path: startAssessment, component: StartAssessment },
    {path: assessmentTreatmentTabs, component: AssessmentTreatmentPlanReview},
    {path: assessmentTreatmentRegeneration, component: RegenerateTreatmentPlan},
    {path: assessmentTreatmentShowDetails, component: ShowDetailsTreatmentPlan},
  ];
};

const hiddenSidebarRoutes = [ROUTES.private.assessmentTreatmentReview];

const modules = (authStore) => {

  return (
    <Lazyload>
      <Switch>
        {routes().map((routeProps) => (
          <Route key={String(routeProps.path)}  {...routeProps} />
        ))}
        <Redirect to={authStore.defaultRoute} />
      </Switch>
    </Lazyload>
  );
};

const PrivateModule = observer(() => {
  const location = useLocation();
  const classes = useStyles();
  const urlMaxLength = 41;
  const authStore = container.get<AuthStore>(AuthStore.diToken);
  const [isHidenMenu, setIsHiddenMenu] = useState(false);
  const clinicianStore = container.get<ClinicianStore>(ClinicianStore.diToken);

  useEffect(() => {
    const isHidden = hiddenSidebarRoutes.includes(location.pathname);
    setIsHiddenMenu(isHidden);

  }, [location.pathname]);

  useEffect(() => {
    if(authStore.profile.profile.role === 'MasterClinician' 
      ||
      authStore.profile.profile.role === 'SeniorClinician'
      || authStore.profile.profile.role === 'Clinician') {
      clinicianStore.updateNotAssignedAssessmentsInfo();
      clinicianStore.getAssessmentsTreatmentPlansInfo();
    } else {
      clinicianStore.notAssignedAssessmentCount = 0;
      clinicianStore.totalNotApprovedEntitiesCount = 0;
    }
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      <Helmet>
        <link
          rel="icon"
          type="image/png"
          href="/public/favicons/favicon-32x32.png"
          sizes="16x16"
        />
      </Helmet>
      <CssBaseline />
      {!isHidenMenu && location.pathname.length < urlMaxLength && (
        <AppBar position="absolute" className={clsx(classes.appBar)}>
          <Toolbar className={classes.toolbar}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Family Hope Center
            </Typography>
            <ProfileMenu />
          </Toolbar>
        </AppBar>
      )
      }
      {!isHidenMenu && location.pathname.length < urlMaxLength && (
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper),
            paperAnchorDockedLeft: clsx(classes.drawerAnchor),
          }}
        >
          <div
            className={classes.topPadding}
          />
          <NavigationMenu
            notAssignedAssessmentCount={clinicianStore.notAssignedAssessmentCount}
            totalNotApprovedEntitiesCount={clinicianStore.totalNotApprovedEntitiesCount}
          />
        </Drawer>
      )
      }
      {!isHidenMenu && location.pathname.length < urlMaxLength ?
        (
          <>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container className={classes.container}>
            {modules(authStore)}
          </Container>
        </main>
          </>
        ) : (
          <>
          <main className={classes.content}>
          {modules(authStore)}
          </main>
          </>
        )
      }
    </div>
  );
});

export default PrivateModule;