import { injectable } from 'inversify';
import { Family, FamilyDTO } from '@shared/models/family';
import BaseService from '@core/services/base';
import { AxiosInstance } from 'axios';
import HttpClient from '@core/HttpClient';
import container from '@core/di';
import { Profile } from '@Client/shared/models/profile';
import { PaymentMethod } from '@shared/models/paymentmethod';

@injectable()
export default class ClientProfileService extends BaseService<
  FamilyDTO,
  Family
> {
  static diToken = Symbol('profile-service-client');
  private httpClient: AxiosInstance;

  constructor() {
    super({
      domainArea: 'client-primary-accounts',
      Model: Family,
      collection: 'clientprimaryaccount',
    });
    this.httpClient = container
      .get<HttpClient>(HttpClient.diToken)
      .getInstance();
  }

  async current() {
    const { data } = await this.httpClient.get<Profile>(this.getURL(`current`));

    return data;
  }

  async getPaymentMethod() {
    const { data } = await this.httpClient.get<PaymentMethod>(
      this.getURL('current/subscriptions/payment-method')
    );

    return data;
  }

  async deleteSubscription() {
    return await this.httpClient.delete(this.getURL(`current/subscriptions`));
  }

  updatePaymantMethod(payMentMethod) {
    return this.httpClient.put(
      this.getURL('current/subscriptions/payment-method'),
      payMentMethod
    );
  }
}
