import MomentUtils from '@date-io/moment';
import * as moment from 'moment';

export default class DateUtils extends MomentUtils {
  date(value: string | Date) {
    return moment.utc(value);
  }
}

export function daysBetween(date1, date2) {

  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;

  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(date1 - date2);

  // Convert back to days and return
  return Math.round(differenceMs / ONE_DAY);

}

export const getMonthsBetweenDates = (birthday: string) => {
  const startDate = moment(birthday, 'YYYY-MM-DD');
  const endDate = moment(moment(), 'YYYY-MM-DD');

  return endDate.diff(startDate, 'months');
};