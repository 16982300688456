import './polyfill';
import 'reflect-metadata';
import 'react-hot-loader';

import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';

import theme from '@core/theme';
import history from '@shared/utils/history';
import Admin from './apps/Admin';
import Config from '@core/config';
import container from '@core/di';
import AuthManager from '@core/AuthManager';
import HttpClient from '@core/HttpClient';
import {AuthStore} from '@shared/stores';
import Notification from '@shared/components/Notification';
import AuditService from '@shared/services/audit';

const App = () => {

    const config = container.get<Config>(Config.diToken);
    const authManager = container.get<AuthManager>(AuthManager.diToken);
    const auditorService = container.get<AuditService>(AuditService.diToken);

    config.initialize();
    auditorService.initialize();

    authManager.initialize('admin');

    const authStore = container.get<AuthStore>(AuthStore.diToken);
    
    authStore.initialize({
        getDeviceId: auditorService.getDeviceId,
    });

    const { apiURL, apiSSO } = config.get().admin;

    const httpClient = container.get<HttpClient>(HttpClient.diToken);
    httpClient.initialize({
        baseUrl: `${apiURL.origin}${apiURL.pathname}`,
        apiSSOUrl: `${apiSSO.origin}${apiSSO.pathname}`,
        getUserTokens:  () => authStore.getUserTokens(),
        handleSignIn: () => {
            authStore.reset();
            authStore.handleLogin();
        }
    });

    return (
        <Router history={history}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <Notification />
                <Admin />
            </MuiThemeProvider>
        </Router>
    );
};

ReactDOM.render((<App />),document.getElementById('root'));
