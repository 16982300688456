import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

interface Props extends SvgIconProps { }

const ErrorIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M14.9327 13.375L8.43272 2.125C8.33584 1.95781 8.16866 1.875 7.99991 1.875C7.83116 1.875 7.66241 1.95781 7.56709 2.125L1.0671 13.375C0.874908 13.7094 1.11553 14.125 1.49991 14.125H14.4999C14.8843 14.125 15.1249 13.7094 14.9327 13.375ZM7.49991 6.5C7.49991 6.43125 7.55616 6.375 7.62491 6.375H8.37491C8.44366 6.375 8.49991 6.43125 8.49991 6.5V9.375C8.49991 9.44375 8.44366 9.5 8.37491 9.5H7.62491C7.55616 9.5 7.49991 9.44375 7.49991 9.375V6.5ZM7.99991 12C7.80365 11.996 7.61677 11.9152 7.47939 11.775C7.34201 11.6348 7.26506 11.4463 7.26506 11.25C7.26506 11.0537 7.34201 10.8652 7.47939 10.725C7.61677 10.5848 7.80365 10.504 7.99991 10.5C8.19617 10.504 8.38304 10.5848 8.52042 10.725C8.65781 10.8652 8.73475 11.0537 8.73475 11.25C8.73475 11.4463 8.65781 11.6348 8.52042 11.775C8.38304 11.9152 8.19617 11.996 7.99991 12Z" fill="#DC3545" />
    </SvgIcon>
  );
};

export default ErrorIcon;