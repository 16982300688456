import { injectable } from 'inversify';
import container from '@core/di';
import BaseStore from '@core/stores/base';
import LifequalityService, { GetLifeQualityReportsListParams } from '@shared/services/lifequality';
import { action } from 'mobx';
import { LifeQualityItem, LifeQualityItemDTO } from '@shared/models/lifequality';

@injectable()
export default class LifeQualityStore extends BaseStore<
  LifeQualityItemDTO,
  LifeQualityItem,
  LifequalityService,
  GetLifeQualityReportsListParams
> {
  static diToken = Symbol('lifequality-store');

  constructor() {
    super({
      service: container.get<LifequalityService>(LifequalityService.diToken),
    });

    this.loading = {
      list: true,
      item: false,
    };
  }

  @action getList = async (params?: GetLifeQualityReportsListParams) => {
    this.loading.list = true;

    try {
      const { items, pageInfo } = await this.service.getLifeQualityReportsList(params);

      this._list = items;
      this._pageInfo = pageInfo;

      return {
        items,
        pageInfo
      };
    } finally {
      this.loading.list = false;
    }
  }
}
