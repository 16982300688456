
export type InterventionVideoUpdate = {
  treatmentOptionIds?: number[],
  title?: string,
};

export interface TreatmentOption {
  id: number;
  name: string;
}

export interface InterventionDTO {
  id: number;
  name: string;
  treatmentOptions: TreatmentOption[];
}

export class Intervention {
  id: number;
  name: string;
  treatmentOptions: TreatmentOption[];

  constructor(dto: InterventionDTO) {
    this.update(dto);
  }

  get asJson(): InterventionDTO {
    return {
      id: this.id,
      name: this.name,
      treatmentOptions: this.treatmentOptions,
    };
  }

  update(newData: Partial<InterventionDTO>): InterventionDTO {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
