import { injectable } from 'inversify';

import container from '@core/di';
import AuthService from '@shared/services/auth';
import BaseStore from '@core/stores/base';
import { Auth, AuthDTO } from '@shared/models/auth';
import { action, computed, observable } from 'mobx';
import history from '@shared/utils/history';
import ROUTES from '@Admin/shared/constants/routes';
import { ROLES } from '@shared/models/employee';
import { FeaturesPermissionFactory, IAFeaturesPermissionFactory } from '@Admin/factories/FeaturesPermissionFactory';
import { FEATURES } from '@Admin/shared/constants/featurePermissionMatrix';

const LOGIN_REDIRECT_URL_KEY = 'fhc_loginRedirectURL';

export type userProfile = {
  amr: string[];
  auth_time: number;
  idp: string;
  name: string;
  preferred_username: string;
  role: ROLES;
  sub: string;
};

export type authResponse = {
  access_token: string;
  expires_at: number;
  id_token: string;
  profile: userProfile;
  refresh_token: string;
  scope: string;
  session_state: string;
  token_type: string;
};

export enum TokenRefreshStatus {
  refreshing,
  refreshed,
  initial,
}

@injectable()
export default class AuthStore extends BaseStore<AuthDTO, Auth, AuthService> {
  static diToken = Symbol('auth-store');
  private factory = container.get<IAFeaturesPermissionFactory>(FeaturesPermissionFactory.diToken);
  private paramsForAudit?: { getDeviceId: () => string };

  @observable private _loggedIn: boolean | undefined;
  @observable private _profile: authResponse;
  @observable tokenRefreshStatus = TokenRefreshStatus.initial;
  @observable private _restrictedRoleLoggedIn = false;

  constructor() {
    super({ service: container.get<AuthService>(AuthService.diToken) });
    this.service = container.get<AuthService>(AuthService.diToken);

    this._loggedIn = undefined;

    this.loading = {
      list: false,
      item: false,
    };
  }

  initialize(params?: AuthStore['paramsForAudit']) {
    this.service.initUserManagerActions({
      onUserLoaded: this.handleUserLoaded,
    });

    this.loadUserFromStore();
    this.paramsForAudit = params;
  }

  get restrictedRoleLoggedIn() {
    return this._restrictedRoleLoggedIn;
  }

  private handleUserLoaded = (authRes?: authResponse) => {
    if (authRes) {
      this.validateLoggedInUser(authRes);
      this.setUser(authRes);
    }
  }

  validateLoggedInUser(authRes: authResponse) {
    const allowedRolesToLogin = [ROLES.SuperAdministrator, ROLES.Admin, ROLES.Clinician, ROLES.MasterClinician, ROLES.SeniorClinician];

    if (!allowedRolesToLogin.includes(authRes.profile.role)) {
      this._restrictedRoleLoggedIn = true;
    }
  }

  private getParamsRequiredForAuthentication() {
    return {
      DeviceId: this.paramsForAudit?.getDeviceId(),
    };
  }

  private async loadUserFromStore() {
    try {
      const user = await this.service.getUser();
      if (!user) {
        this._loggedIn = false;

        return;
      }
      this.setUser(user);
    } catch (e) {}
  }

  private setUser(user: authResponse) {
    this._profile = user;
    this._loggedIn = true;
  }

  async getUserTokens() {
    const user = await this.service.getUser();

    return {
      refresh: user.refresh_token,
      access: user.access_token,
    };
  }

  get loginRedirectURL() {
    return localStorage.getItem(LOGIN_REDIRECT_URL_KEY) ?? this.defaultRoute;
  }

  setLoginRedirectURL(url: string) {
    localStorage.setItem(LOGIN_REDIRECT_URL_KEY, url);
  }

  logout = () => {
    return this.service.logout();
  }

  handleLogin() {
    this.service.handleLogin(this.getParamsRequiredForAuthentication());
  }

  @action isFeatureAvailable = (feature: FEATURES) => {
    return this.factory.buildUserAvailableFeatures(this._profile.profile.role).includes(feature);
  };

  async signinRedirectCallback() {
    const authData = await this.service.signinRedirectCallback();
    this.handleUserLoaded(authData);
    history.push(this.loginRedirectURL);
  }

  reset() {
    localStorage.removeItem(LOGIN_REDIRECT_URL_KEY);
    this.service.reset();
    this._loggedIn = false;
    history.push(`${ROUTES.public.login}`);
  }

  get profile(): authResponse {
    return this._profile;
  }

  // set profile(value: Profile) {
  set profile(value: authResponse) {
    this._profile = value;
  }

  // @computed get userProfile(): Profile {
  @computed get userProfile(): userProfile {
    return this._profile && this._profile.profile;
  }

  @computed get loggedIn(): boolean | undefined {
    return this._loggedIn;
  }

  @computed get defaultRoute(): string {
    return (this.userProfile?.role === ROLES.Admin || this.userProfile?.role === ROLES.SuperAdministrator)
      ? ROUTES.private.employee
      : ROUTES.private.families;
  }
}
