import { injectable } from 'inversify';
import { featurePermissionMatrix } from '@Admin/shared/constants/featurePermissionMatrix';
import { ROLES } from '@shared/models/employee';

export interface IAFeaturesPermissionFactory {
  buildUserAvailableFeatures: (role: ROLES) => string[];
}

@injectable()
export class FeaturesPermissionFactory implements IAFeaturesPermissionFactory {
  static diToken = Symbol('feature-permission-factory');

  buildUserAvailableFeatures(role) {
    return featurePermissionMatrix.filter((item) => item.roles.includes(role)).map((feature) => feature.featureName);
  }
} 