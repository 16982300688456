import { injectable } from 'inversify';

import BaseService from '@core/services/base';
import { AxiosInstance } from 'axios';
import HttpClient from '@core/HttpClient';
import container from '@core/di';
import { AssessmentListItem, AssessmentListItemDTO } from '@shared/models/assesmentlistitem';

@injectable()
export default class AssessmentsHistoryService extends BaseService<AssessmentListItemDTO, AssessmentListItem> {
  static diToken = Symbol('assessmentshistory-service');
  private httpClient: AxiosInstance;

  constructor() {
    super({ domainArea: 'client-primary-accounts', Model: AssessmentListItem});
    this.httpClient = container.get<HttpClient>(HttpClient.diToken).getInstance();

  }

  async getAssessmentsHistoryList(clientPrimaryAccountId: number, childId: number) {
    const { data } = await this.httpClient.get<AssessmentListItem>(
      this.getURL(`${clientPrimaryAccountId}`)
    );

    return data;
  }

}
