import { injectable } from 'inversify';
import { Family, FamilyDTO } from '@shared/models/family';
import container from '@core/di';
import BaseStore from '@core/stores/base';
import { action, computed, observable } from 'mobx';
import ProfileService from '../services/profile';
import { Profile } from '../models/profile';
import { PaymentMethod } from '@shared/models/paymentmethod';

@injectable()
export default class ClientProfileStore extends BaseStore<
  FamilyDTO,
  Family,
  ProfileService
> {
  static diToken = Symbol('profile-store-client');
  @observable private _profile: Profile;
  @observable private _paymentMethod: PaymentMethod;

  constructor() {
    super({ service: container.get<ProfileService>(ProfileService.diToken) });
  }

  @action current = async () => {
    const profile = await this.service.current();
    this._profile = profile;

    return profile;
  };

  @computed get profile(): Profile {
    return this._profile;
  }

  set profile(profile: Profile) {
    this._profile = profile;
  }

  @action getPaymentMethod = async () => {
    const payMentMethod =  await this.service.getPaymentMethod();
    this._paymentMethod = payMentMethod;

    return payMentMethod;
  };

  @computed get paymentMethod(): PaymentMethod {
    return this._paymentMethod;
  }

  set paymentMethod(payMentMethod: PaymentMethod) {
    this._paymentMethod = payMentMethod;
  }

  @action deleteSubscription = async () => {
    return   this.service.deleteSubscription();
  }

  @action updatePaymantMethod = async (payMentMethod) => {
    return   this.service.updatePaymantMethod(payMentMethod);
  }

}
