import {BrainFunction} from '@shared/models/matrix';
import { evaluationRatingValue } from '@Admin/shared/constants/evaluationRating';
import {ASSESSMENT_DOMAINS} from '@shared/constants/assessmentdomains';

export enum EvaluationReviewStatus {
  EvaluatorCompleted= 'EvaluatorCompleted',
  ReviewerCompleted = 'ReviewerCompleted',
}

export type Evaluation = {
  brainFunctionId: number,
  evaluationRating: string,
  reviewStatus?: EvaluationReviewStatus,
};

export type BrainFunctionEvaluation = BrainFunction & {evaluation: Evaluation};

export class Domain {
  constructor(public evaluations: BrainFunctionEvaluation[], public markedAsNotYetAchieved: boolean, public domainDescription: string){}

  get cellRating(): number {
    const level = this.evaluations[0]?.level?.brainLevelId;
    const domain = this.evaluations[0]?.domain;

    const calculateCellRating = (evaluations) => {
      const ratings = evaluations.filter((item) => item.evaluation?.evaluationRating);
      const sumRatings = ratings.reduce((acc, cur) => acc+evaluationRatingValue[cur.evaluation.evaluationRating], 0 );
      const cellRating = sumRatings / ratings.length;

      return cellRating;
    };

    switch(true) {
      case level === 3 && domain === ASSESSMENT_DOMAINS.HEARING_UNDERSTANDING: {
        const ratings = this.evaluations.map((item) => evaluationRatingValue[item.evaluation?.evaluationRating]);

        const lastItem = ratings[ratings.length-1];
        const penultItem = ratings[ratings.length-2];
        const sum = lastItem+penultItem;
        const isNYA = [...ratings].every((item) => item === evaluationRatingValue.NYA);

        if(isNYA) {
          return evaluationRatingValue.NYA;
        }

        if(sum < 1 || sum === 1) {
          return evaluationRatingValue.PA;
        }

        return calculateCellRating(this.evaluations);

      }

      case level === 4 && domain === ASSESSMENT_DOMAINS.EMOTIONAL_SOCIAL: {
        const ratings = this.evaluations.map((item) => evaluationRatingValue[item.evaluation?.evaluationRating]);
        const lastItem = ratings[ratings.length-1];
        const isNYA = [...ratings].every((item) => item === evaluationRatingValue.NYA);

        if(isNYA) {
          return evaluationRatingValue.NYA;
        }
        if(lastItem === evaluationRatingValue.NYA) {
          return evaluationRatingValue.PA;
        }

        return calculateCellRating(this.evaluations);
      }

     default: {
      return calculateCellRating(this.evaluations);
     }
    }
  }
}

export type DomainsEvaluations = {
  CommunicationSpeech: Domain,
  EmotionalSocial: Domain,
  HearingUnderstanding: Domain,
  LocomotionSpeech: Domain,
  ManualWriting: Domain,
  SeeingReading: Domain,
  SensationTactility: Domain,
};

export type CategoriesEvaluations = {
  Motor?: DomainsEvaluations,
  Sensory?: DomainsEvaluations,
  Social?: DomainsEvaluations,
};

export type ChildAgeModel = {
  CommunicationSpeech: number
  EmotionalSocial: number
  HearingUnderstanding: number
  LocomotionSpeech: number
  ManualWriting: number
  SeeingReading: number
  SensationTactility: number
};

export type AbilitiesNote = {
  brainFunctionId: number,
  text: string
};

export type AdditionalCriteriaNote = {
  clinicalCriterionId: number,
  text: string
};
