import * as React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { AuthStore } from '@shared/stores';
import container from '@core/di';
import { Avatar, Button } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Modal from '@shared/components/Modal';
import { AvatarGroup } from '@material-ui/lab';
import AuthManager from '@core/AuthManager';
import AvatarImage from '@public/assets/images/avatar.png';
import history from '@shared/utils/history';
import ROUTES from '@Admin/shared/constants/routes';
import ProfileStore from '@shared/stores/profile';
import { observer } from 'mobx-react';

const useStyles = makeStyles(({ palette: { colors } }: Theme) => ({
    paper: {
        top: '50px!important',
        minWidth: '200px'
    },
    root: {
        marginRight: '20px',
        '&:hover': {
            borderRadius: 0
        }
    },
    list: {
        width: '220px'
    },
    buttonRoot: {
        backgroundColor: 'unset',
        boxShadow: 'none',
        border: 'none',
        fontWeight: 500,
        fontSize: 14,
        color: '#fff',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'unset',
        }
    },
    buttonLabel: {
        fontWeight: 500,
        fontSize: 14,
        color: '#cae7ea',
    },
    endIcon: {
        marginLeft: 49,
    },
    avatar: {
        height: 28,
        width: 28
    },
    avatarGroup: {
        border: `1px solid #ffffff`,
    },

}
));

interface ProfileMenuProps {
}

const ProfileMenu: React.FC<ProfileMenuProps> = observer(() => {
    // const ProfileMenu: React.FC<ProfileMenuProps> =  () => {
    const classes = useStyles();
    // const { config, logo, moduleRef, setView } = container.get<LayoutStore>(LayoutStore.diToken);

    const authManager = container.get<AuthManager>(AuthManager.diToken);
    const profileStore = container.get<ProfileStore>(ProfileStore.diToken);

    const { profile } = profileStore;

    const manager = authManager.get();

    const [isModalLogoutShown, setIsModalLogoutShown] = React.useState(false);

    const authStore = container.get<AuthStore>(AuthStore.diToken);

    const { userProfile: { name, sub } } = authStore;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        setIsModalLogoutShown(true);
    };

    const handleOpenProfile = () => {
        handleClose();
        history.push(ROUTES.private.profile);
    };

    const handleChangePassword = () => {
        handleClose();
        const url = `${manager.settings.authority}/Account/ChangePassword?UserId=${sub}&returnUrl=${window.location.href}&postLogoutRedirectUrl=${manager.settings.post_logout_redirect_uri}`;
        window.location.href = url;
    };

    const actionLogout = () => {
        localStorage.removeItem('regenerateTreatmentInformation');
        authStore.logout();
    };

    React.useEffect(() => {
        profileStore.current();
    }, []);

    return (
        <div>
            <Button
                variant="contained"
                onClick={handleMenu}
                classes={{
                    root: classes.buttonRoot,
                    label: classes.buttonLabel,
                    endIcon: classes.endIcon,
                }}
                startIcon={(
                    <AvatarGroup
                        max={2}
                        classes={{
                            avatar: classes.avatarGroup,
                        }}
                    >
                        <Avatar
                            alt="My avatar"
                            src={(profile?.profileImageUrl || AvatarImage)}
                            classes={{
                                root: classes.avatar
                            }}
                        />
                    </AvatarGroup>
                )}
                endIcon={<ArrowDropDownIcon style={{ color: '#fff' }} />}
            >
                {((profile?.firstName && profile?.lastName) ? `${profile?.firstName} ${profile?.lastName} ` : name)}

            </Button>

            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                classes={{
                    paper: classes.paper,
                    list: classes.list,
                }}
                onClose={handleClose}
            >
                <MenuItem onClick={handleOpenProfile}>View my profile</MenuItem>
                <MenuItem onClick={handleChangePassword}>
                    Change password</MenuItem>
                <MenuItem onClick={handleLogout}>Log out</MenuItem>
            </Menu>
            {
                isModalLogoutShown && (

                    <Modal
                        onClose={() => setIsModalLogoutShown(false)}
                        title="Are you sure you want to log out?"
                        submitAction={actionLogout}
                        submitText="LOG OUT"
                    />
                )
            }
        </div>

    );
});
// };

export default ProfileMenu;
