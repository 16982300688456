import { injectable } from 'inversify';
import { Family, FamilyDTO } from '@shared/models/family';
import container from '@core/di';
import FamiliesService, { GetFamiliesParams } from '@shared/services/families';
import BaseStore from '@core/stores/base';
import { action, observable, computed } from 'mobx';

@injectable()
export default class FamiliesStore extends BaseStore<FamilyDTO, Family, FamiliesService, GetFamiliesParams> {
    static diToken = Symbol('families-store');

    @observable private _childId = '';
    @observable private _clientPrimaryAccountFirstName = '';
    @observable private _clientPrimaryAccountLastName = '';

    constructor() {
        super({ service: container.get<FamiliesService>(FamiliesService.diToken) });

        this.loading = {
            list: false,
            item: false,
        };
    }

    @computed get childId() {
        return this._childId;
    }
    @computed get clientPrimaryAccountFirstName() {
        return this._clientPrimaryAccountFirstName;
    }
    @computed get clientPrimaryAccountLastName() {
        return this._clientPrimaryAccountLastName;
    }

    @action getFamilyInfo = async (uId: string) => {
        const familyData = await this.service.getFamilyInfo(uId);
        this._childId = familyData.child?.id.toString();
        this._clientPrimaryAccountFirstName = familyData.firstName;
        this._clientPrimaryAccountFirstName = familyData.lastName;

        return familyData;
    }

    @action getList = async (params?: GetFamiliesParams) => {
        this.loading.list = true;

        try {
            const { items, pageInfo } = await this.service.getFamiliesList(params);

            this._list = items;
            this._pageInfo = pageInfo;

            return {
                items,
                pageInfo
            };
        } finally {
            this.loading.list = false;
        }
    }

    @action getAssessmentsList = async (clientPrimaryAccountId: string, childId: string) => {
        return await this.service.getAssessmentsList(clientPrimaryAccountId, childId);
    }

    @action getAssessmentsHistory = async (clientPrimaryAccountId: string, childId: number, page: number, pageSize: number) => {
        return await this.service.getAssessmentsHistory(clientPrimaryAccountId, childId, page, pageSize);
    }

    getDraftAssessment =  (clientPrimaryAccountId, childId) => {
        return  this.service.getDraftAssessment(clientPrimaryAccountId, childId);
      };

    getRemainingAssessments = (clientPrimaryAccountId: string) => {
        return this.service.getRemainingAssessments(clientPrimaryAccountId);
    };

    @action addExtraAssessment = async (clientPrimaryAccountId: string) => {
        return await this.service.addExtraAssessment(clientPrimaryAccountId);
    };

    @action getAssessment = async (clientPrimaryAccountId: string, childId: string, assessmentId: string) => {
        return await this.service.getAssessment(clientPrimaryAccountId, childId, assessmentId);
    }

    @action getTreatmentPlan = async (clientPrimaryAccountId: string, childId: string, planId: number) => {
        return await this.service.getTreatmentPlan(clientPrimaryAccountId, childId, planId);
    }

    @action getCurrentTreatmentPlan = async (clientPrimaryAccountId: string, childId: string) => {
        return await this.service.getCurrentTreatmentPlan(clientPrimaryAccountId, childId);
    }

    // getOptionsProgress
    @action getOptionsProgress = async (clientPrimaryAccountId: string, childId: string, treatmentOptionId: number) => {
        return await this.service.getOptionsProgress(clientPrimaryAccountId, childId, treatmentOptionId);
    }

    @action getQalityReport = async (clientPrimaryAccountId: string, id: string) => {
        return await this.service.getQalityReport(clientPrimaryAccountId, id);
    }

    @action current = async () => {
        return await this.service.current();
    }

    @action assignClinician = async (params: {clinicianId: string, clientId: string}) => {
        return this.service.assignClinician(params);
    }

    @action unassignClinician = async (clientId: string) => {
        return this.service.unassignClinician(clientId);
    }

    @action callCompletedPlanReview = async (clientPrimaryAccountId: string) => {
        return this.service.callCompletedPlanReview(clientPrimaryAccountId);
    }
}
