import { injectable } from 'inversify';
import BaseService from '@core/services/base';
import Config from '@core/config';
import { HttpProgressParams, ListRequestParams, ListResponse } from '@shared/types/services';
import { SortingType } from '@shared/constants/services';
import Axios, { AxiosInstance } from 'axios';
import HttpClient from '@core/HttpClient';
import container from '@core/di';
import { FamilyInfo } from '@shared/models/familyInfo';
import { Assessment, AssessmentDTO } from '@shared/models/assessment';
import { AssessmentsMatrix } from '@shared/models/matrix';
import { ASSESSMENT_SUBSCRIPTIONS_NUMBERS } from '@shared/constants/assessments';
import { Video } from '@shared/models/video';
import { getQueries } from '@shared/utils/common';

export interface GetAssessmentsListParams {
  Page?: number;
  PageSize?: number;
  IsAssigned?: boolean;
  'Sort.Field'?: string;
  'Sort.Type'?: SortingType;
}

@injectable()
export default class AssessmentsService extends BaseService<
  AssessmentDTO,
  Assessment
> {
  static diToken = Symbol('assessments-service');
  private httpClient: AxiosInstance;

  private cancelToken = Axios.CancelToken;
  private source = this.cancelToken.source();

  constructor() {
    super({
      domainArea: 'assessments',
      Model: Assessment,
      collection: 'assessments',
    });
    this.httpClient = container
      .get<HttpClient>(HttpClient.diToken)
      .getInstance();
  }

  async getAssessmentsList(params?: GetAssessmentsListParams) {
    try {
      const { data } = await Axios.get<ListResponse<AssessmentDTO>>(this.urlPrefix, {
        params: params ?? this.getAssessmentsListParams(),
      });

      const { items, pageInfo } = data;

      return {
        pageInfo,
        items: items.map((dto) => new Assessment(dto)),
      };
    } catch (err) {
      throw err;
    }
  }

  private getAssessmentsListParams(): GetAssessmentsListParams {
    const queries: ListRequestParams = getQueries();

    return {
      'Sort.Field': queries.sorting?.field ?? 'submitted',
      'Sort.Type': queries.sorting?.type ?? SortingType.desc,
      Page: queries.pagination?.page,
      PageSize: queries.pagination?.pageSize,
      IsAssigned: queries.isAssigned,
    };
  }

  async getFamilyInfo(uId: string) {
    const { data } = await this.httpClient.get<FamilyInfo>(
      this.getURL(`${uId}`)
    );

    return data;
  }

  async getMatrix() {
    const { data } = await this.httpClient.get<AssessmentsMatrix>(
      this.getURL(`matrix`)
    );

    return data;
  }

  async videoThumbnails(brainFunctionId: number) {
    const { data } = await this.httpClient.get<Video[]>(
      this.getURL(`brain-functions/${brainFunctionId}/video-thumbnails`)
    );

    return data;
    // TODO: add id in API
    // return data.map((video) => ({ ...video, id: Date.now() } as Video));
  }

  async renameClientVideo(
    brainFunctionId: number,
    subscriptionPlanId: ASSESSMENT_SUBSCRIPTIONS_NUMBERS,
    title: string
  ) {
    const { data } = await this.httpClient.put(
      this.getURL(
        `brain-functions/${brainFunctionId}/subscriptions/${subscriptionPlanId}/video`
      ),
      { title }
    );

    return data;
  }

  async deleteClientVideo(
    brainFunctionId: number,
    subscriptionPlanId: ASSESSMENT_SUBSCRIPTIONS_NUMBERS
  ) {
    const { data } = await this.httpClient.delete(
      this.getURL(
        `brain-functions/${brainFunctionId}/subscriptions/${subscriptionPlanId}/video`
      )
    );

    return data;
  }

  public cancelUpload() {
    this.source.cancel();
  }

  async uploadClientVideo(
    brainFunctionId: number,
    subscriptionPlanId: ASSESSMENT_SUBSCRIPTIONS_NUMBERS,
    formData,
    progressCallback
  ) {
    this.cancelToken = Axios.CancelToken;
    this.source = this.cancelToken.source();
    try {
      const res = await this.httpClient.post(
        this.getURL(
          `brain-functions/${brainFunctionId}/subscriptions/${subscriptionPlanId}/video`
        ),
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
          onUploadProgress: (progressData: HttpProgressParams) => {
            progressCallback(
              Math.round((100 * progressData.loaded) / progressData.total)
            );
          },
          cancelToken: this.source.token,
        }
      );

      const { data } = res;

      return data;
    } catch (e) {
      if (!Axios.isCancel(e)) {
        throw e;
      }
    }
  }

  updateTreatmentPlanModel(formData: FormData, progressCallback: (value: number) => void) {
    this.cancelToken = Axios.CancelToken;
    this.source = this.cancelToken.source();

    return this.httpClient.post(
      this.getURL('treatment-plan-model-template'),
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data'
        },
        onUploadProgress: (progressData: HttpProgressParams) => {
          progressCallback(Math.round((100 * progressData.loaded) / progressData.total));
        },
         cancelToken: this.source.token,
      },
    );
  }

  async getMatrixAssessments() {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'get',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `assessments/matrix`
    });

    return data;
  }

  async getAssessmentList(pagination) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().admin;

    const { data } = await this.httpClient({
      method: 'get',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `clinician/not-assigned-assessments?Page=${pagination.page}&PageSize=${pagination.pageSize}`,
    });

    return data;
  }

  async getClinicianVideo(brainFunctionId: number) {
    const { data } = await this.httpClient.get<Video>(
      this.getURL(`brain-functions/${brainFunctionId}/video`)
    );

    return data;
  }

  async renameClinicianVideo(
    brainFunctionId: number,
    title: string
  ) {
    const { data } = await this.httpClient.put(
      this.getURL(
        `brain-functions/${brainFunctionId}/video`
      ),
      { title }
    );

    return data;
  }

  async deleteClinicianVideo(
    brainFunctionId: number,
  ) {
    const { data } = await this.httpClient.delete(
      this.getURL(
        `brain-functions/${brainFunctionId}/video`
      )
    );

    return data;
  }

  async uploadClinicianVideo(
    brainFunctionId: number,
    formData,
    progressCallback
  ) {
    this.cancelToken = Axios.CancelToken;
    this.source = this.cancelToken.source();

    try {
      const res = await this.httpClient.post(
        this.getURL(
          `brain-functions/${brainFunctionId}/video`
        ),
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
          onUploadProgress: (progressData: HttpProgressParams) => {
            progressCallback(
              Math.round((100 * progressData.loaded) / progressData.total)
            );
          },
          cancelToken: this.source.token,
        }
      );

      const { data } = res;

      return data;
    } catch (e) {
      if (!Axios.isCancel(e)) {
        throw e;
      }
    }
  }
}
